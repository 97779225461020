// PPFOptions.jsx
import React, { useState } from 'react';
import { Box, Typography, TextField, Button, IconButton } from '@mui/material';
import { Add, Delete } from '@mui/icons-material';

function PPFOptions({ ppfOptions: initialOptions = [], onUpdate }) {
  const [options, setOptions] = useState(initialOptions);

  const handleAddRoll = () => {
    const newRoll = {
      width: '',
      totalLength: '',
      cost: '',
      inventory: ''
      // remainingLength will default to totalLength on save
    };
    const updated = [...options, newRoll];
    setOptions(updated);
    onUpdate(updated);
  };

  const handleRollChange = (index, field, value) => {
    const updated = options.map((roll, i) =>
      i === index ? { ...roll, [field]: value } : roll
    );
    setOptions(updated);
    onUpdate(updated);
  };

  const handleRemoveRoll = (index) => {
    const updated = options.filter((_, i) => i !== index);
    setOptions(updated);
    onUpdate(updated);
  };

  return (
    <Box sx={{ mt: 2 }}>
      <Typography variant="h6">PPF Options</Typography>
      {options.map((roll, index) => (
        <Box 
          key={index} 
          sx={{ display: 'flex', gap: 2, mb: 1, flexWrap: 'wrap', alignItems: 'center' }}
        >
          <TextField
            label="Width (in)"
            value={roll.width}
            onChange={(e) => handleRollChange(index, 'width', e.target.value)}
            type="number"
            sx={{ minWidth: 100 }}
          />
          <TextField
            label="Length (ft)"
            value={roll.totalLength}
            onChange={(e) => handleRollChange(index, 'totalLength', e.target.value)}
            type="number"
            sx={{ minWidth: 100 }}
          />
          <TextField
            label="Cost"
            value={roll.cost}
            onChange={(e) => handleRollChange(index, 'cost', e.target.value)}
            type="number"
            sx={{ minWidth: 100 }}
          />
          <TextField
            label="Inventory"
            value={roll.inventory}
            onChange={(e) => handleRollChange(index, 'inventory', e.target.value)}
            type="number"
            sx={{ minWidth: 100 }}
          />
          <IconButton onClick={() => handleRemoveRoll(index)} color="error">
            <Delete />
          </IconButton>
        </Box>
      ))}
      <Button variant="outlined" onClick={handleAddRoll} startIcon={<Add />} sx={{ mt: 1 }}>
        Add Roll
      </Button>
    </Box>
  );
}

export default PPFOptions;
