// src/contexts/ThemeContext.js
import React, { createContext, useContext, useState, useMemo } from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import highTechTheme from '../theme';

const ThemeContext = createContext();

export const useThemeMode = () => useContext(ThemeContext);

export function ThemeContextProvider({ children }) {
  const [mode, setMode] = useState('dark'); // default to dark

  const theme = useMemo(() => {
    // Merge highTechTheme with mode overrides if needed.
    return createTheme({
      ...highTechTheme,
      palette: {
        ...highTechTheme.palette,
        mode,
        background: {
          default: mode === 'dark' ? '#1C1C1C' : '#F5F5F5',
          paper: mode === 'dark' ? '#2F4F4F' : '#FFF',
        },
        text: {
          primary: mode === 'dark' ? '#FFFFFF' : '#000000',
          secondary: mode === 'dark' ? '#C0C0C0' : '#555555',
        },
      },
    });
  }, [mode]);

  const toggleTheme = () =>
    setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));

  return (
    <ThemeContext.Provider value={{ toggleTheme }}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </ThemeContext.Provider>
  );
}
