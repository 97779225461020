// src/utils/axiosAuth.js
import axios from 'axios';

const axiosAuthInstance = axios.create({
  baseURL: process.env.REACT_APP_AUTH_SERVICE_URL, // Base URL for Auth Service
  headers: {
    'Content-Type': 'application/json',
  },
});

axiosAuthInstance.interceptors.request.use((config) => {
  const token = localStorage.getItem('token'); // Retrieve token from local storage
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`; // Attach Authorization header
  }
  // Add ownerId header if available
  const ownerId = localStorage.getItem('ownerId');
  if (ownerId) {
    config.headers['X-Owner-Id'] = ownerId;
  }
  return config;
});

export default axiosAuthInstance;
