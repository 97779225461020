import React, { useState, useEffect } from 'react';
import { Box, Button, Checkbox, FormControlLabel, TextField, Typography } from '@mui/material';
import axiosInstance from '../../../utils/axiosAppointment';
import { useOwnerId } from '../../../App';

function DynamicQuestionnaire({ onComplete }) {
  const ownerId = useOwnerId();
  const [questions, setQuestions] = useState([]);
  const [responses, setResponses] = useState({});

  useEffect(() => {
    async function fetchQuestions() {
      try {
        const response = await axiosInstance.get('/questionnaire', {
          params: { ownerId },
        });
        setQuestions(response.data.questions || []);
      } catch (error) {
        console.error('Error fetching questionnaire configuration:', error);
      }
    }
    if (ownerId) {
      fetchQuestions();
    }
  }, [ownerId]);

  const handleInputChange = (questionId, value) => {
    setResponses((prev) => ({ ...prev, [questionId]: value }));
  };

  const handleCheckboxChange = (questionId, optionId) => {
    const currentSelections = responses[questionId] || [];
    if (currentSelections.includes(optionId)) {
      setResponses((prev) => ({
        ...prev,
        [questionId]: currentSelections.filter((opt) => opt !== optionId),
      }));
    } else {
      setResponses((prev) => ({
        ...prev,
        [questionId]: [...currentSelections, optionId],
      }));
    }
  };

  const handleSubmit = () => {
    onComplete(responses);
  };

  return (
    <Box sx={{ p: 4, backgroundColor: '#1C1C1C', color: '#C0C0C0' }}>
      <Typography variant="h4" gutterBottom>
        Guided Questionnaire
      </Typography>
      {questions.length > 0 ? (
        questions.map((q) => (
          <Box key={q._id || q.id} sx={{ mb: 3 }}>
            <Typography variant="h6">{q.question}</Typography>
            {q.type === 'rating' && (
              <TextField
                type="number"
                value={responses[q._id] || ''}
                onChange={(e) => handleInputChange(q._id, e.target.value)}
                InputProps={{ inputProps: { min: 1, max: 5 } }}
                sx={{ mt: 1 }}
              />
            )}
            {q.type === 'text' && (
              <TextField
                type="text"
                value={responses[q._id] || ''}
                onChange={(e) => handleInputChange(q._id, e.target.value)}
                sx={{ mt: 1 }}
              />
            )}
            {q.type === 'checkbox' && q.options && (
              <Box sx={{ mt: 1 }}>
                {q.options.map((opt) => (
                  <FormControlLabel
                    key={opt._id || opt.id}
                    control={
                      <Checkbox
                        checked={
                          responses[q._id]?.includes(opt._id || opt.id) || false
                        }
                        onChange={() =>
                          handleCheckboxChange(q._id, opt._id || opt.id)
                        }
                        sx={{ color: '#39FF14' }}
                      />
                    }
                    label={opt.label}
                    sx={{ color: '#C0C0C0' }}
                  />
                ))}
              </Box>
            )}
          </Box>
        ))
      ) : (
        <Typography>No questions available.</Typography>
      )}
      <Button
        variant="contained"
        onClick={handleSubmit}
        sx={{ mt: 2, backgroundColor: '#39FF14', color: '#1C1C1C' }}
      >
        Submit
      </Button>
    </Box>
  );
}

export default DynamicQuestionnaire;
