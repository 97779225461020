import React, { useState, useEffect } from "react";
import axiosInstance from "../../utils/axiosAppointment";
import "./PackageManagement.css"; // Ensure styling is consistent
import DeleteConfirmationModal from "./DeleteConfirmationModal";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const PanelManagement = ({ onClose }) => {
  const [panels, setPanels] = useState([]);
  const [services, setServices] = useState([]);
  const [vehicleClassifications, setVehicleClassifications] = useState([]);
  const [showPanelForm, setShowPanelForm] = useState(false);
  const [editingPanel, setEditingPanel] = useState(null);
  const [newPanel, setNewPanel] = useState({
    name: "",
    category: "",
    applicableServices: [],
    defaultClassifications: [], // for vehicle classifications
  });
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedPanel, setSelectedPanel] = useState(null);

  useEffect(() => {
    fetchPanels();
    fetchServices();
    fetchVehicleClassifications();
  }, []);

  const fetchPanels = async () => {
    try {
      const response = await axiosInstance.get("/panels");
      setPanels(response.data);
    } catch (error) {
      console.error("Error fetching panels:", error);
    }
  };

  const fetchServices = async () => {
    try {
      const response = await axiosInstance.get("/services");
      setServices(response.data);
    } catch (error) {
      console.error("Error fetching services:", error);
    }
  };

  const fetchVehicleClassifications = async () => {
    try {
      // Adjust the endpoint if needed based on your API routing.
      const response = await axiosInstance.get("/vehicle-classifications");
      setVehicleClassifications(response.data);
    } catch (error) {
      console.error("Error fetching vehicle classifications:", error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewPanel((prevPanel) => ({ ...prevPanel, [name]: value }));
  };

  const handleServiceSelection = (serviceId) => {
    setNewPanel((prevPanel) => ({
      ...prevPanel,
      applicableServices: prevPanel.applicableServices.includes(serviceId)
        ? prevPanel.applicableServices.filter((id) => id !== serviceId)
        : [...prevPanel.applicableServices, serviceId],
    }));
  };

  const handleClassificationSelection = (classificationId) => {
    setNewPanel((prevPanel) => ({
      ...prevPanel,
      defaultClassifications: prevPanel.defaultClassifications.includes(classificationId)
        ? prevPanel.defaultClassifications.filter((id) => id !== classificationId)
        : [...prevPanel.defaultClassifications, classificationId],
    }));
  };

  const resetForm = () => {
    setNewPanel({
      name: "",
      category: "",
      applicableServices: [],
      defaultClassifications: [],
    });
    setEditingPanel(null);
  };

  const handleSavePanel = async () => {
    if (!newPanel.name || !newPanel.category) {
      toast.error("Please fill in all required fields.");
      return;
    }

    try {
      if (editingPanel) {
        // Update existing panel
        await axiosInstance.put(`/panels/${editingPanel._id}`, newPanel);
        toast.success("Panel updated successfully!");
      } else {
        // Create new panel
        await axiosInstance.post("/panels", newPanel);
        toast.success("Panel added successfully!");
      }
      fetchPanels();
      resetForm();
      setShowPanelForm(false);
    } catch (error) {
      console.error("Error saving panel:", error);
      toast.error("Error saving panel.");
    }
  };

  const handleEditPanel = (panel) => {
    setEditingPanel(panel);
    setNewPanel({
      name: panel.name,
      category: panel.category,
      applicableServices: panel.applicableServices?.map((s) => s._id) || [],
      defaultClassifications: panel.defaultClassifications?.map((c) => c._id) || [],
    });
    setShowPanelForm(true);
  };

  const handleDeletePanel = async () => {
    if (!selectedPanel) return;
    try {
      await axiosInstance.delete(`/panels/${selectedPanel}`);
      toast.success("Panel deleted successfully!");
      setShowDeleteModal(false);
      fetchPanels();
    } catch (error) {
      console.error("Error deleting panel:", error);
      toast.error("Error deleting panel.");
    }
  };

  return (
    <div className="panel-popup">
      <div className="panel-popup-content">
        <h3>Manage Panels</h3>
        <button className="close-btn" onClick={onClose}>X</button>

        <button onClick={() => {
          resetForm();
          setShowPanelForm(!showPanelForm);
        }}>
          {showPanelForm ? "Close Form" : "Add New Panel"}
        </button>

        {showPanelForm && (
          <div className="panel-form">
            <input
              type="text"
              name="name"
              value={newPanel.name}
              placeholder="Panel Name"
              onChange={handleInputChange}
            />
            <select
              name="category"
              value={newPanel.category}
              onChange={handleInputChange}
            >
              <option value="">Select Category</option>
              <option value="Body Panel">Body Panel</option>
              <option value="Glass Panel">Glass Panel</option>
            </select>
            
            <div className="service-selection">
              <h4>Applicable Services</h4>
              {services.map((service) => (
                <label key={service._id}>
                  <input
                    type="checkbox"
                    checked={newPanel.applicableServices.includes(service._id)}
                    onChange={() => handleServiceSelection(service._id)}
                  />
                  {service.name}
                </label>
              ))}
            </div>

            <div className="classification-selection">
              <h4>Vehicle Classifications</h4>
              {vehicleClassifications.map((classification) => (
                <label key={classification._id}>
                  <input
                    type="checkbox"
                    checked={newPanel.defaultClassifications.includes(classification._id)}
                    onChange={() => handleClassificationSelection(classification._id)}
                  />
                  {classification.name}
                </label>
              ))}
            </div>

            <button onClick={handleSavePanel}>
              {editingPanel ? "Update Panel" : "Add Panel"}
            </button>
            {editingPanel && (
              <button onClick={resetForm}>Cancel Edit</button>
            )}
          </div>
        )}

        <table className="panel-table">
          <thead>
            <tr>
              <th>Name</th>
              <th>Category</th>
              <th>Services</th>
              <th>Classifications</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {panels.map((panel) => (
              <tr key={panel._id}>
                <td>{panel.name}</td>
                <td>{panel.category}</td>
                <td>
                  {panel.applicableServices && panel.applicableServices.length > 0 ? (
                    panel.applicableServices.map(service => service.name).join(", ")
                  ) : (
                    "No Service Assigned"
                  )}
                </td>
                <td>
                  {panel.defaultClassifications && panel.defaultClassifications.length > 0 ? (
                    panel.defaultClassifications.map(classif => classif.name).join(", ")
                  ) : (
                    "No Classification Assigned"
                  )}
                </td>
                <td>
                  <button onClick={() => handleEditPanel(panel)}>Edit</button>
                  <button onClick={() => {
                    setSelectedPanel(panel._id);
                    setShowDeleteModal(true);
                  }}>Delete</button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        {showDeleteModal && (
          <DeleteConfirmationModal
            message="Are you sure you want to delete this panel?"
            onConfirm={handleDeletePanel}
            onCancel={() => setShowDeleteModal(false)}
          />
        )}
      </div>
    </div>
  );
};

export default PanelManagement;
