import React, { useState, useEffect } from "react";
import axiosInstance from "../../utils/axiosAppointment";
import CreatePackage from "./CreatePackage";
import CreateVehicleClassification from "./CreateVehicleClassification";
import "./PackageManagement.css";
import ListPackages from "./ListPackages";
import PanelManagement from "./PanelManagement";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { jwtDecode } from "jwt-decode";

const PackageManagement = ({ brandId, serviceId, brandName }) => {
  const [subBrands, setSubBrands] = useState([]);
  const [packages, setPackages] = useState([]);
  const [ownerId, setOwnerId] = useState(null);
  const [showCreatePackage, setShowCreatePackage] = useState(false);
  const [editingPackage, setEditingPackage] = useState(null);
  const [showCreateVehicleClass, setShowCreateVehicleClass] = useState(false);
  const [showPanelManagement, setShowPanelManagement] = useState(false);
  const [panels, setPanels] = useState([]);
  const [isCreatingPackage, setIsCreatingPackage] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      const decoded = jwtDecode(token);
      setOwnerId(decoded.ownerId);
    }

    if (brandId && serviceId) {
      fetchSubBrands();
      fetchPackages();
      fetchPanels();
    }
  }, [brandId, serviceId]);

  const fetchPanels = async () => {
    try {
      const response = await axiosInstance.get(`/panels/service/${serviceId}`);
      setPanels(response.data);
    } catch (error) {
      console.error("Error fetching panels:", error);
    }
  };

  const fetchSubBrands = async () => {
    try {
      const response = await axiosInstance.get(
        `/brands/${brandId}/sub-brands?serviceId=${serviceId}`
      );
      setSubBrands(response.data);
    } catch (error) {
      console.error("Error fetching sub-brands:", error);
    }
  };

  const fetchPackages = async () => {
    try {
      const response = await axiosInstance.get(
        `/packages/service/${serviceId}/brand/${brandId}`
      );
      setPackages(response.data);
    } catch (error) {
      console.error("Error fetching packages:", error);
    }
  };

  const handleCreatePackage = () => {
    setShowCreatePackage(!showCreatePackage);
    setEditingPackage(null);
    setShowCreateVehicleClass(false);
    setShowPanelManagement(false);
  };

  const handlePackageCreated = () => {
    fetchPackages(); // ✅ Refresh package list
    setShowCreatePackage(false); // ✅ Close the modal
};

  const handleEditPackage = (pkg) => {
    setEditingPackage(pkg);
    setShowCreatePackage(true);
  };

  const handleCreateVehicleClass = () => {
    setShowCreateVehicleClass(!showCreateVehicleClass);
    setShowCreatePackage(false);
    setShowPanelManagement(false);
  };

  const handlePanelManagement = () => {
    setShowPanelManagement(!showPanelManagement);
    setShowCreatePackage(false);
    setShowCreateVehicleClass(false);
  };

  return (
    <div className="package-management">
      <h4 className="header">Packages for {brandName}</h4>

      <div className="button-group">
        <button onClick={handleCreatePackage}>
          {showCreatePackage ? "Close Package Creator" : "Create Package"}
        </button>
        <button onClick={handleCreateVehicleClass}>
          {showCreateVehicleClass
            ? "Close Vehicle Class Creator"
            : "Create Vehicle Classification"}
        </button>
        <button onClick={handlePanelManagement}>Manage Panels</button>
      </div>

      {showCreatePackage && (
        <CreatePackage
          serviceId={serviceId}
          brandId={brandId}
          ownerId={ownerId}
          panels={panels}
          onPackageCreated={handlePackageCreated}
          onCancel={() => setShowCreatePackage(false)}
        />
      )}

      {/* Render List Packages Table */}
      <ListPackages
        serviceId={serviceId}
        brandId={brandId}
        packages={packages}
        subBrands={subBrands}
        onEditPackage={handleEditPackage}
        fetchPackages={fetchPackages}
      />

      {showCreateVehicleClass && <CreateVehicleClassification />}
      {showPanelManagement && <PanelManagement onClose={() => setShowPanelManagement(false)} />}
    </div>
  );
};

export default PackageManagement;
