import React from 'react';
import DynamicQuestionnaire from './DynamicQuestionnaire';
import { Container, Typography } from '@mui/material';

function TestDynamicQuestionnaire() {
  const handleComplete = (responses) => {
    console.log('Questionnaire Responses:', responses);
  };

  return (
    <Container maxWidth="sm" sx={{ mt: 4 }}>
      <Typography variant="h3" color="primary" gutterBottom>
        Test Dynamic Questionnaire
      </Typography>
      <DynamicQuestionnaire onComplete={handleComplete} />
    </Container>
  );
}

export default TestDynamicQuestionnaire;
