import React, { useState, useEffect } from "react";
import axios from "../../utils/axiosAppointment";
import SubBrandDropdownMenu from "../Brands/BrandCrud/SubBrandDropdownMenu";

const CreatePackage = ({ serviceId, brandId, ownerId, onPackageCreated, onCancel }) => {
  const [packageName, setPackageName] = useState("");
  const [price, setPrice] = useState(0);
  const [description, setDescription] = useState("");
  const [subBrands, setSubBrands] = useState([]);
  const [selectedSubBrand, setSelectedSubBrand] = useState({ id: "", name: "" });
  const [panels, setPanels] = useState([]);
  const [selectedPanels, setSelectedPanels] = useState([]);
  const [loading, setLoading] = useState(false);
  const [vehicleClassifications, setVehicleClassifications] = useState([]);
  const [selectedVehicleClassification, setSelectedVehicleClassification] = useState("");


  /** Fetch Sub-Brands */
  useEffect(() => {
    const fetchSubBrands = async () => {
      if (!serviceId || !brandId) return;
      try {
        const response = await axios.get(`/brands/${brandId}/sub-brands?serviceId=${serviceId}`);
        setSubBrands(response.data);
      } catch (error) {
        console.error("Error fetching sub-brands:", error);
      }
    };
    fetchSubBrands();
  }, [serviceId, brandId]);

  /** Fetch Panels */
  useEffect(() => {
    const fetchPanels = async () => {
      if (!serviceId) return;
      try {
        const response = await axios.get(`/panels/service/${serviceId}`);
        setPanels(response.data);
      } catch (error) {
        console.error("Error fetching panels:", error);
      }
    };
    fetchPanels();
  }, [serviceId]);

  /** Handle Panel Selection */
  const handlePanelSelection = (panelId) => {
    setSelectedPanels((prev) =>
      prev.includes(panelId) ? prev.filter((id) => id !== panelId) : [...prev, panelId]
    );
  };

  useEffect(() => {
    const fetchVehicleClassifications = async () => {
      try {
        const response = await axios.get("/vehicle-classifications");
        setVehicleClassifications(response.data);
      } catch (error) {
        console.error("Error fetching vehicle classifications:", error);
      }
    };
    fetchVehicleClassifications();
  }, []);

  /** Handle Package Submission */
  const handleSubmit = async () => {
    if (!selectedSubBrand.id || !selectedSubBrand.name) {
        console.error("Error: SubBrandId or SubBrandName is missing.");
        return;
    }
    if (!ownerId) {
        console.error("Error: OwnerId is missing.");
        return;
    }

    setLoading(true);
    try {
        const payload = {
            name: packageName,
            price,
            description,
            serviceId,
            brandId,
            ownerId,
            subBrandId: selectedSubBrand.id,
            subBrandName: selectedSubBrand.name,
            vehicleClassificationId: selectedVehicleClassification,
            panels: selectedPanels.length ? selectedPanels.filter(id => id) : [],
        };

        console.log("Submitting package payload:", payload);
        await axios.post("/packages", payload);

        // ✅ Refresh list and close the modal BEFORE alert
        onPackageCreated();

        setTimeout(() => {
            alert("Package successfully created!");
            onCancel(); 
        }, 500); 

    } catch (error) {
        console.error("Error creating package:", error);
    } finally {
        setLoading(false);
    }
};

  return (
    <div className="create-package-container">
      <h3>Create Package</h3>

      <input
        type="text"
        placeholder="Package Name"
        value={packageName}
        onChange={(e) => setPackageName(e.target.value)}
      />

      <input
        type="number"
        placeholder="Price"
        value={price}
        onChange={(e) => setPrice(Number(e.target.value))}
      />

      <textarea
        placeholder="Description"
        value={description}
        onChange={(e) => setDescription(e.target.value)}
      />

      <h4>Select SubBrand</h4>
      <SubBrandDropdownMenu
        brandId={brandId}
        serviceId={serviceId}
        selectedSubBrand={selectedSubBrand}
        setSelectedSubBrand={setSelectedSubBrand}
      />

        <div className="form-group">
        <label>Vehicle Classification:</label>
        <select
            value={selectedVehicleClassification}
            onChange={(e) => setSelectedVehicleClassification(e.target.value)}
        >
            <option value="">Select a classification</option>
            {vehicleClassifications.map((classification) => (
            <option key={classification._id} value={classification._id}>
                {classification.name}
            </option>
            ))}
        </select>
        </div>

      <h4>Select Panels</h4>
      <div className="panel-selection">
        {panels.length > 0 ? (
          panels.map((panel) => (
            <label key={panel._id}>
              <input
                type="checkbox"
                value={panel._id}
                checked={selectedPanels.includes(panel._id)}
                onChange={() => handlePanelSelection(panel._id)}
              />
              {panel.name}
            </label>
          ))
        ) : (
          <p>No panels available for this service.</p>
        )}
      </div>

      <div className="button-group">
        <button onClick={handleSubmit} disabled={loading}>
          {loading ? "Creating..." : "Create Package"}
        </button>
        <button onClick={onCancel} className="cancel-button">
          Cancel
        </button>
      </div>
    </div>
  );
};

export default CreatePackage;
