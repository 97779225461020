// Navbar.jsx
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode'; // Corrected import statement
import '../../styles/Navbar.css'; // Ensure the path is correct
import DriveSuiteLogo from '../../assets/images/Drive Suite Website.png';

function Navbar() {
  const token = localStorage.getItem('token');
  const navigate = useNavigate();
  let userRole = null;
  
  if (token) {
    try {
      const decodedToken = jwtDecode(token);
      userRole = decodedToken.accountLevelRole || decodedToken.appLevelRole;
    } catch (error) {
      console.error('Invalid token:', error);
    }
  }
  
  const handleLogout = () => {
    localStorage.removeItem('token');
    navigate('/');
  };

  const [isFinanceDropdownOpen, setFinanceDropdownOpen] = useState(false);
  const toggleFinanceDropdown = () => setFinanceDropdownOpen(!isFinanceDropdownOpen);
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

  return (
    <nav className="navbar">
      <div className="navbar-container">
        {/* Logo Integration */}
        <Link to="/" className="navbar-logo">
          <img src={DriveSuiteLogo} alt="Drive Suite Logo" className="navbar-logo-image" />
        </Link>
        
        {/* Mobile Menu Icon */}
        <button
          className="mobile-menu-icon"
          onClick={() => setMobileMenuOpen(!isMobileMenuOpen)}
          aria-label="Toggle navigation menu"
        >
          <div className={`hamburger ${isMobileMenuOpen ? 'open' : ''}`}></div>
        </button>
        
        {/* Navbar Links */}
        <div className={`navbar-links ${isMobileMenuOpen ? 'active' : ''}`}>
          {token ? (
            <>
              {userRole === 'Owner' && <Link to="/dashboard" className="navbar-link">Dashboard</Link>}
              {['Owner', 'Manager'].includes(userRole) && <Link to="/services-dashboard" className="navbar-link">Services</Link>}
              {['Owner', 'Manager'].includes(userRole) && <Link to="/scheduler-dashboard" className="navbar-link">Scheduler</Link>}
              {userRole === 'Owner' && <Link to="/customers" className="navbar-link">Customers</Link>}
              {userRole === 'Owner' && <Link to="/business-profile" className="navbar-link">Business Profile</Link>}
              {['Owner', 'Manager'].includes(userRole) && <Link to="/employees" className="navbar-link">Employees</Link>}
              {userRole === 'App-Admin' && <Link to="/admin" className="navbar-link">Admin</Link>}

              {/* Finances Dropdown */}
              {['Owner', 'Manager', 'Employee'].includes(userRole) && (
                <div className="navbar-dropdown">
                  <button onClick={toggleFinanceDropdown} className="dropdown-btn">
                    Finances
                    <i className={`arrow ${isFinanceDropdownOpen ? 'up' : 'down'}`}></i>
                  </button>
                  {isFinanceDropdownOpen && (
                    <div className="dropdown-menu">
                      <Link to="/invoices" className="dropdown-item" onClick={() => setFinanceDropdownOpen(false)}>Invoice Dashboard</Link>
                      {/* Add more dropdown items here */}
                    </div>
                  )}
                </div>
              )}

              <button onClick={handleLogout} className="logout-btn">Logout</button>
            </>
          ) : (
            <>
              <Link to="/login" className="navbar-link">Login</Link>
              <Link to="/register" className="navbar-link">Register</Link>
            </>
          )}
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
