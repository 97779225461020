// App.js
import React, { createContext, useEffect, useState, useContext } from 'react';
import { Routes, Route } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { useSearchParams } from 'react-router-dom';
import CreateAdmin from './components/CreateAdmin';


// Common Components
import Navbar from './components/Shared/Navbar';
import PrivateRoute from './components/Shared/PrivateRoute';
import { getSubdomain } from './utils/getSubdomain';
import ServiceDashboard from './components/Services/ServiceDashboard/ServiceDashboard';

import OnboardingStep1 from './components/Onboarding/OnboardingStep1';
import OnboardingStep2 from './components/Onboarding/OnboardingStep2';
import OnboardingStep3 from './components/Onboarding/OnboardingStep3';
import OnboardingStep4 from './components/Onboarding/OnboardingStep4';
import OnboardingStep5 from './components/Onboarding/OnboardingStep5';

//Questionnaire
import TestDynamicQuestionnaire from './components/Scheduler/Questionnaire/TestDynamicQuestionnaire';
import DynamicQuestionnaire from './components/Scheduler/Questionnaire/DynamicQuestionnaire';
import QuestionnaireBuilder from './components/Scheduler/Questionnaire/QuestionnaireBuilder';

// User Components
import Registration from './components/Registration/Registration';
import CompleteRegistration from './components/Registration/CompleteRegistration';
import Login from './components/Login/Login';
import EditProfile from './components/Profile/EditProfile';

// Dashboard Components
import OwnerDashboard from './components/OwnerDashboard/OwnerDashboard';
import AdminDashboard from './components/AdminDashboard/AdminDashboard';
import AdminServiceManagement from './components/Admin/Services/AdminServiceManagement';
import PricingDashboard from './components/Pricing/PricingDashboard';

// Customer Components
import CustomerList from './components/Customers/CustomerList';
import AddCustomer from './components/Customers/AddCustomer';
import EditCustomer from './components/Customers/EditCustomer';
import CustomerDetails from './components/Customers/CustomerDetails';

// Invoice Components
import InvoiceList from './components/Invoice/InvoiceList';
import InvoiceDetails from './components/Invoice/InvoiceDetails';
import InvoiceForm from './components/Invoice/InvoiceForm';
import PaymentForm from './components/Invoice/PaymentForm';
import InvoiceDashboard from './components/Invoice/InvoiceDashboard';

// Sales Tax Components
import SalesTaxForm from './components/Settings/SalesTaxForm';
import SalesTaxList from './components/Settings/SalesTaxList';


// Vehicle Components
import VehicleList from './components/Vehicles/VehicleList';
import AddVehicle from './components/Vehicles/AddVehicle';
import EditVehicle from './components/Vehicles/EditVehicle';
import VehicleDetails from './components/Vehicles/VehicleDetails';

// Service, Brand, and Pricing Components
import ServiceManagement from './components/Services/ServiceManagement/ServiceManagement';
import ServiceSettings from './components/Services/ServiceSettings/ServiceSettings';
import BrandManagement from './components/Brands/BrandManagement';
import PricingManagement from './components/Pricing/PricingManagement';

// Scheduler
import SchedulerDashboard from './components/Scheduler/SchedulerDashboard';
import BayManagement from './components/Bays/BayManagement';
import PublicScheduler from './components/Scheduler/PublicScheduler';
import PublicSchedulerWizard from './components/Scheduler/SchedulerWizard/PublicSchedulerWizard';

// Admin Components
import ManageUser from './components/AdminDashboard/Users/ManageUser';

// Subscription and Home Components
import SubscriptionPlans from './components/SubscriptionPlans/SubscriptionPlans';
import UpdateSubscription from './components/Subscription/UpdateSubscription';
import Home from './components/Home/Home';

// Business Profile Component
import BusinessProfile from './components/BusinessProfile/BusinessProfile';

// Employee Management Components
import EmployeeList from './components/Employees/EmployeeList';

// Stripe Setup
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// Stripe Setup
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY || '');

if (!process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY) {


}

export const OwnerContext = createContext(null);

// A custom hook for consuming the ownerId easily
export function useOwnerId() {
  return useContext(OwnerContext);
}

function App() {
  const [ownerId, setOwnerId] = useState(null);
  const [isOwnerIdLoading, setIsOwnerIdLoading] = useState(true);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    // Check for token in the query string
    const token = searchParams.get('token');
    if (token) {
      localStorage.setItem('token', token); // Save token in localStorage
      searchParams.delete('token'); // Clean up the URL
      window.history.replaceState(null, '', window.location.pathname);
    }
  
    const subdomain = getSubdomain();
    if (!subdomain) {
      // No subdomain: default behavior (e.g., main domain)
      setIsOwnerIdLoading(false);
      return;
    }
  
    // Retrieve stored token (if any)
    const storedToken = localStorage.getItem('token');
    // Determine if we are already on a public route
    const isOnPublicPage = window.location.pathname.includes('public-scheduler') ||
                             window.location.pathname.includes('public-scheduler-wizard');
  
    // If there's no token and the user is not already on a public page, redirect to public scheduler
    if (!storedToken && !isOnPublicPage) {
      window.location.href = '/public-scheduler';
      return;
    }
  
    async function fetchOwnerId() {
      try {
        let response;
        // Check if the current route is public
        const isPublicRoute = window.location.pathname.includes('public-scheduler');
        if (storedToken) {
          // Authenticated fetch
          response = await fetch(`${process.env.REACT_APP_AUTH_SERVICE_URL}/users/getOwnerIdBySubdomain?subdomain=${subdomain}`, {
            headers: { Authorization: `Bearer ${storedToken}` },
          });
        } else if (isPublicRoute) {
          // Public fetch without token
          response = await fetch(`${process.env.REACT_APP_AUTH_SERVICE_URL}/users/getOwnerIdBySubdomain?subdomain=${subdomain}`);
        }
  
        if (!response.ok) {
          throw new Error(`Failed to fetch ownerId: ${response.statusText}`);
        }
  
        const data = await response.json();
        setOwnerId(data.ownerId);
        // Save it in localStorage for the API client to use:
        localStorage.setItem('ownerId', data.ownerId);
      } catch (error) {
        console.error('Error fetching ownerId:', error.message);
        setOwnerId(null);
        window.location.href = `https://drivesuitecrm.com`;
      } finally {
        setIsOwnerIdLoading(false);
      }
    }
  
    fetchOwnerId();
  }, [searchParams]);

  if (isOwnerIdLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <OwnerContext.Provider value={ownerId}>
        <Navbar />
        <Elements stripe={stripePromise}>
          <Routes>
            {/* Public Routes */}
           
            <Route path="/" element={<Home />} />
            <Route path="/plans" element={<SubscriptionPlans />} />
            <Route path="/register" element={<Registration />} />
            <Route path="/complete-registration" element={<CompleteRegistration />} />
            <Route path="/login" element={<Login />} />
            <Route
              path="/public-scheduler"
              element={<PublicScheduler ownerId={ownerId} />}
            />
            <Route
              path="/public-scheduler-wizard"
              element={<PublicSchedulerWizard ownerId={ownerId} />}
            />
            <Route path="/testquestionnaire" element={<TestDynamicQuestionnaire/>} />
            <Route path="/questionnaire" element={<DynamicQuestionnaire/>} />

          {/* Protected Routes for Owners */}
          <Route
            path="/dashboard"
            element={
              <PrivateRoute roles={['Owner']}>
                <OwnerDashboard />
              </PrivateRoute>
            }
          />
          <Route
            path="/questionnaire-builder"
            element={
              <PrivateRoute roles={['Owner']}>
                <QuestionnaireBuilder />
              </PrivateRoute>
            }
          />
          <Route
            path="/pricing-dashboard"
            element={
              <PrivateRoute roles={['Owner', 'Manager']}>
                <PricingDashboard />
              </PrivateRoute>
            }
          />
          <Route
            path="/onboarding/step1"
            element={
              <PrivateRoute roles={['Owner']}>
                <OnboardingStep1 />
              </PrivateRoute>
            }
          />
          <Route
            path="/onboarding/step2"
            element={
              <PrivateRoute roles={['Owner']}>
                <OnboardingStep2 />
              </PrivateRoute>
            }
          />
          <Route
            path="/onboarding/step3"
            element={
              <PrivateRoute roles={['Owner']}>
                <OnboardingStep3 />
              </PrivateRoute>
            }
          />
          <Route
            path="/onboarding/step4"
            element={
              <PrivateRoute roles={['Owner']}>
                <OnboardingStep4 />
              </PrivateRoute>
            }
          />
          <Route
            path="/onboarding/step5"
            element={
              <PrivateRoute roles={['Owner']}>
                <OnboardingStep5 />
              </PrivateRoute>
            }
          />
          <Route
            path="/profile/edit"
            element={
              <PrivateRoute roles={['Owner']}>
                <EditProfile />
              </PrivateRoute>
            }
          />
          <Route
            path="/update-subscription"
            element={
              <PrivateRoute roles={['Owner']}>
                <UpdateSubscription />
              </PrivateRoute>
            }
          />

          {/* Add the route for ServiceDashboard */}
          <Route
            path="/services-dashboard"
            element={
              <PrivateRoute roles={['Owner', 'Manager']}>
                <ServiceDashboard />
              </PrivateRoute>
            }
          />

          <Route
            path="/scheduler-dashboard"
            element={
              <PrivateRoute roles={['Owner', 'Manager']}>
                <SchedulerDashboard />
              </PrivateRoute>
            }
          />
          <Route
            path="/scheduler/bay-management"
            element={
              <PrivateRoute roles={['Owner', 'Manager']}>
                <BayManagement />
              </PrivateRoute>
            }
          />

          <Route
            path="/settings/tax-rates"
            element={
              <PrivateRoute roles={['Owner', 'Manager']}>
                <SalesTaxList />
              </PrivateRoute>
            }
          />

          <Route
            path="/settings/tax-rates/new"
            element={
              <PrivateRoute roles={['Owner', 'Manager']}>
                <SalesTaxForm />
              </PrivateRoute>
            }
          />

          <Route
            path="/settings/tax-rates/edit/:id"
            element={
              <PrivateRoute roles={['Owner', 'Manager']}>
                <SalesTaxForm />
              </PrivateRoute>
            }
          />

          {/* Business Profile */}
          <Route
            path="/business-profile"
            element={
              <PrivateRoute roles={['Owner']}>
                <BusinessProfile />
              </PrivateRoute>
            }
          />

          {/* Invoice Management Routes */}
          <Route
            path="/invoices"
            element={
              <PrivateRoute roles={['Owner', 'Manager', 'Employee']}>
                <InvoiceDashboard />
              </PrivateRoute>
            }
          />
          <Route
            path="/invoicelist"
            element={
              <PrivateRoute roles={['Owner', 'Manager', 'Employee']}>
                <InvoiceList />
              </PrivateRoute>
            }
          />
          <Route
            path="/invoices/new"
            element={
              <PrivateRoute roles={['Owner', 'Manager', 'Employee']}>
                <InvoiceForm />
              </PrivateRoute>
            }
          />
          <Route
            path="/invoices/:id"
            element={
              <PrivateRoute roles={['Owner', 'Manager', 'Employee']}>
                <InvoiceDetails />
              </PrivateRoute>
            }
          />
          <Route
            path="/invoices/:id/edit"
            element={
              <PrivateRoute roles={['Owner', 'Manager', 'Employee']}>
                <InvoiceForm />
              </PrivateRoute>
            }
          />
          <Route
            path="/invoices/:id/payment"
            element={
              <PrivateRoute roles={['Owner', 'Manager', 'Employee']}>
                <PaymentForm />
              </PrivateRoute>
            }
          />

          {/* Customer Management */}
          <Route
            path="/customers"
            element={
              <PrivateRoute roles={['Owner']}>
                <CustomerList />
              </PrivateRoute>
            }
          />
          <Route
            path="/customers/add"
            element={
              <PrivateRoute roles={['Owner']}>
                <AddCustomer />
              </PrivateRoute>
            }
          />
          <Route
            path="/customers/:id"
            element={
              <PrivateRoute roles={['Owner']}>
                <CustomerDetails />
              </PrivateRoute>
            }
          />
          <Route
            path="/customers/:id/edit"
            element={
              <PrivateRoute roles={['Owner']}>
                <EditCustomer />
              </PrivateRoute>
            }
          />

          {/* Vehicle Management */}
          <Route
            path="/vehicles"
            element={
              <PrivateRoute roles={['Owner']}>
                <VehicleList />
              </PrivateRoute>
            }
          />
          <Route
            path="/vehicles/add"
            element={
              <PrivateRoute roles={['Owner']}>
                <AddVehicle />
              </PrivateRoute>
            }
          />
          <Route
            path="/vehicles/:id"
            element={
              <PrivateRoute roles={['Owner']}>
                <VehicleDetails />
              </PrivateRoute>
            }
          />
          <Route
            path="/vehicles/:id/edit"
            element={
              <PrivateRoute roles={['Owner']}>
                <EditVehicle />
              </PrivateRoute>
            }
          />

          {/* Service, Brand, and Pricing Management */}
          <Route
            path="/services"
            element={
              <PrivateRoute roles={['Owner']}>
                <ServiceManagement />
              </PrivateRoute>
            }
          />
          <Route
            path="/services/new"
            element={
              <PrivateRoute roles={['Owner']}>
                <ServiceSettings />
              </PrivateRoute>
            }
          />
          <Route
            path="/services/:serviceId"
            element={
              <PrivateRoute roles={['Owner']}>
                <ServiceSettings />
              </PrivateRoute>
            }
          />
          <Route
            path="/brands"
            element={
              <PrivateRoute roles={['Owner']}>
                <BrandManagement />
              </PrivateRoute>
            }
          />
          <Route
            path="/pricing"
            element={
              <PrivateRoute roles={['Owner']}>
                <PricingManagement />
              </PrivateRoute>
            }
          />

          {/* Employee Management Route */}
          <Route
            path="/employees"
            element={
              <PrivateRoute roles={['Owner', 'Manager']}>
                <EmployeeList />
              </PrivateRoute>
            }
          />

          {/* Admin Management */}
          <Route
            path="/admin"
            element={
              <PrivateRoute roles={['App-Admin']}>
                <AdminDashboard />
              </PrivateRoute>
            }
          />
          <Route
            path="/admin/users/:userId"
            element={
              <PrivateRoute roles={['App-Admin']}>
                <ManageUser />
              </PrivateRoute>
            }
          />
          <Route
            path="/admin/services"
            element={
              <PrivateRoute roles={['App-Admin']}>
                <AdminServiceManagement />
              </PrivateRoute>
            }
          />
        </Routes>
      </Elements>
      </OwnerContext.Provider>
    </div>
  );
}

export default App;
