import React, { useState, useEffect } from "react";
import axiosInstance from "../../utils/axiosAppointment";
import "./PricingSquareFoot.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { jwtDecode } from "jwt-decode";

const PricingSquareFoot = ({ brandId, serviceId, brandName }) => {
  const [subBrands, setSubBrands] = useState([]);
  const [pricingData, setPricingData] = useState({});
  const [ownerId, setOwnerId] = useState(null);
  const [editing, setEditing] = useState(false);
  const defaultRanges = ["0-0.49", "0.5-1.99", "2.0+"];

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      const decoded = jwtDecode(token);
      setOwnerId(decoded.ownerId);
    }

    if (brandId && serviceId) {
      fetchSubBrands();
    }
  }, [brandId, serviceId]);

  // Fetch SubBrands filtered by brandId and serviceId
  const fetchSubBrands = async () => {
    try {
      const response = await axiosInstance.get(
        `/brands/${brandId}/sub-brands?serviceId=${serviceId}`
      );
      const filteredSubBrands = response.data.filter(
        (subBrand) => subBrand.serviceId === serviceId
      );
      setSubBrands(filteredSubBrands);
      fetchPricingData(filteredSubBrands);
    } catch (error) {
      console.error("Error fetching sub-brands:", error);
    }
  };

  // Fetch Pricing Data for the filtered sub-brands
  const fetchPricingData = async (subBrandsList) => {
    try {
        const newPricingData = {};
        for (const subBrand of subBrandsList) {
            if (!subBrand.squareFootPricing || !subBrand.squareFootPricing._id) {
                console.warn(`No SquareFootPricing ID found for SubBrand: ${subBrand.name}`);
                continue;
            }

            const pricingId = subBrand.squareFootPricing._id;
            const response = await axiosInstance.get(`/square-foot-pricing/${pricingId}`);

            if (response.data && response.data.pricingRanges) {
                // ✅ Ensure the data structure is correctly formatted
                newPricingData[subBrand._id] = {
                    pricingId,
                    ranges: response.data.pricingRanges.reduce((acc, item) => {
                        acc[item.range] = Number(item.price); // Ensure numeric values
                        return acc;
                    }, {})
                };
            } else {
                console.warn(`No pricing data found for pricing ID: ${pricingId}`);
                newPricingData[subBrand._id] = {
                    pricingId,
                    ranges: { "0-0.49": 0, "0.5-1.99": 0, "2.0+": 0 }
                };
            }
        }
        setPricingData(newPricingData);
    } catch (error) {
        console.error("Error fetching pricing data:", error);
    }
};

  // Handle Price Change in Editable Table
  const handlePriceChange = (range, subBrandId, value) => {
    setPricingData((prevPricingData) => ({
        ...prevPricingData,
        [subBrandId]: {
            ...prevPricingData[subBrandId],
            ranges: {
                ...prevPricingData[subBrandId]?.ranges, // Keep existing values
                [range]: value // Update specific range
            }
        }
    }));
};

  const handleSavePricing = async () => {
    try {
        console.log("Saving Pricing Data:", pricingData);

        let hasChanges = false;

        for (const subBrand of subBrands) {
            const pricingId = pricingData[subBrand._id]?.pricingId;

            if (!pricingId) {
                console.warn(`No SquareFootPricing ID found for SubBrand: ${subBrand.name}`);
                continue;
            }

            // ✅ Convert pricing object to an array format expected by MongoDB
            const formattedRanges = Object.entries(pricingData[subBrand._id]?.ranges || {})
                .map(([range, price]) => ({
                    range,
                    price: Number(price) || 0 // Ensure number format
                }));

            if (formattedRanges.length > 0) {
                hasChanges = true;
                await axiosInstance.put(`/square-foot-pricing/${pricingId}`, {
                    pricingRanges: formattedRanges
                });
            }
        }

        if (hasChanges) {
            toast.success("Pricing updated successfully!");
            setEditing(false);
            fetchSubBrands(); // Refresh data
        } else {
            toast.warn("No changes detected.");
        }
    } catch (error) {
        console.error("Error saving pricing:", error);
        toast.error("Error updating pricing.");
    }
};


  return (
    <div className="pricing-square-foot">
      <h4 className="header">Pricing for {brandName} by Sq. Ft.</h4>

      <table className="pricing-table">
        <thead>
          <tr>
            <th>Per Sq. Ft.</th>
            {subBrands.map((subBrand) => (
              <th key={subBrand._id}>{subBrand.name}</th>
            ))}
          </tr>
        </thead>
        <tbody>
    {defaultRanges.map((range, index) => (
        <tr key={index}>
            <td>{range}</td>
            {subBrands.map((subBrand) => (
                <td key={subBrand._id}>
                    {!editing ? (
                        <span>
                            ${pricingData[subBrand._id]?.ranges?.[range] ?? "0"}
                        </span>
                    ) : (
                        <input
                            type="number"
                            value={pricingData[subBrand._id]?.ranges?.[range] ?? ""}
                            onChange={(e) =>
                                handlePriceChange(range, subBrand._id, e.target.value)
                            }
                            className="edit-mode"
                        />
                    )}
                </td>
            ))}
        </tr>
    ))}
</tbody>

      </table>

      <div className="pricing-actions">
        <button className="edit-btn" onClick={() => setEditing(!editing)}>
          {editing ? "Cancel" : "Edit"}
        </button>
        <button
          className="save-btn"
          onClick={handleSavePricing}
          disabled={!editing}
        >
          Save
        </button>
      </div>
    </div>
  );
};

export default PricingSquareFoot;
