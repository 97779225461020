import React, { useState, useEffect } from "react";
import axios from "../../../utils/axiosAppointment";

const SubBrandDropdownMenu = ({ serviceId, brandId, selectedSubBrand, setSelectedSubBrand }) => {
  const [subBrands, setSubBrands] = useState([]);

  useEffect(() => {
    const fetchSubBrands = async () => {
      if (!serviceId || !brandId) return;
      try {
        const response = await axios.get(`/brands/${brandId}/sub-brands?serviceId=${serviceId}`);
        setSubBrands(response.data);
      } catch (error) {
        console.error("Error fetching sub-brands:", error);
      }
    };

    fetchSubBrands();
  }, [serviceId, brandId]);

  return (
    <div>
      <label>Select Sub-Brand:</label>
      <select
        value={selectedSubBrand?.id || ""}
        onChange={(e) => {
          const selected = subBrands.find(sb => sb._id === e.target.value);
          if (setSelectedSubBrand && selected) {
            setSelectedSubBrand({ id: selected._id, name: selected.name });
          } else {
            console.error("setSelectedSubBrand is undefined or invalid selection.");
          }
        }}
      >
        <option value="">Select a Sub-Brand</option>
        {subBrands.map((subBrand) => (
          <option key={subBrand._id} value={subBrand._id}>
            {subBrand.name}
          </option>
        ))}
      </select>
    </div>
  );
};

export default SubBrandDropdownMenu;
