// VehicleSearchMini.jsx
import React, { useState, useEffect } from 'react';
import axiosVehicle from '../../utils/axiosVehicle';
import './VehicleSearchMini.css';

function VehicleSearchMini({ onVehicleAdd }) {
  const [selectedYear, setSelectedYear] = useState('');
  const [selectedMake, setSelectedMake] = useState('');
  const [selectedModel, setSelectedModel] = useState('');
  const [selectedTrim, setSelectedTrim] = useState('');
  const [makes, setMakes] = useState([]);
  const [models, setModels] = useState([]);
  const [trims, setTrims] = useState([]);

  const fetchMongoDBData = async (url, setState, params = {}) => {
    try {
      const response = await axiosVehicle.get(url, { params });
      setState(response.data);
    } catch (error) {
      console.error('Error fetching MongoDB data:', error);
    }
  };

  useEffect(() => {
    if (selectedYear) {
      fetchMongoDBData('/vehicles/search', setMakes, { year: selectedYear });
    } else {
      setMakes([]);
    }
  }, [selectedYear]);
  
  useEffect(() => {
    if (selectedYear && selectedMake) {
      fetchMongoDBData('/vehicles/search', setModels, { year: selectedYear, make: selectedMake });
    } else {
      setModels([]);
    }
  }, [selectedYear, selectedMake]);
  
  useEffect(() => {
    if (selectedYear && selectedMake && selectedModel) {
      fetchMongoDBData('/vehicles/search', setTrims, { year: selectedYear, make: selectedMake, model: selectedModel });
    } else {
      setTrims([]);
    }
  }, [selectedYear, selectedMake, selectedModel]);

  const handleVehicleAdd = async () => {
    if (selectedYear && selectedMake && selectedModel && selectedTrim) {
      try {
        const response = await axiosVehicle.get('/vehicles/search', {
          params: {
            year: selectedYear,
            make: selectedMake,
            model: selectedModel,
            trim: selectedTrim,
          },
        });
        
        if (response.data) {
          let aggregatedVehicle;
          // If the response is an array, aggregate it.
          if (Array.isArray(response.data)) {
            // Start with the first record as a base
            aggregatedVehicle = { ...response.data[0] };
            // Ensure panels is an array
            aggregatedVehicle.panels = [];
            // Loop through each record and collect the panel info
            response.data.forEach(record => {
              if (record.panels) {
                aggregatedVehicle.panels.push(record.panels);
              }
            });
          } else {
            aggregatedVehicle = response.data;
          }
          
          console.log("Aggregated vehicle:", aggregatedVehicle);
          onVehicleAdd(aggregatedVehicle);
        }
      } catch (error) {
        console.error('Error fetching vehicle details:', error);
      }
    }
  };
  return (
    <div className="vehicle-search-mini">
      <select value={selectedYear} onChange={(e) => setSelectedYear(e.target.value)}>
        <option value="">Year</option>
        {[...Array(2025 - 1950 + 1).keys()].map((_, i) => (
          <option key={2025 - i} value={2025 - i}>{2025 - i}</option>
        ))}
      </select>

      <select value={selectedMake} onChange={(e) => setSelectedMake(e.target.value)} disabled={!selectedYear}>
        <option value="">Make</option>
        {makes.map((make) => (
          <option key={make} value={make}>{make}</option>
        ))}
      </select>

      <select value={selectedModel} onChange={(e) => setSelectedModel(e.target.value)} disabled={!selectedMake}>
        <option value="">Model</option>
        {models.map((model) => (
          <option key={model} value={model}>{model}</option>
        ))}
      </select>

      <select value={selectedTrim} onChange={(e) => setSelectedTrim(e.target.value)} disabled={!selectedModel}>
        <option value="">Trim</option>
        {trims.map((trim) => (
          <option key={trim} value={trim}>{trim}</option>
        ))}
      </select>

      <button onClick={handleVehicleAdd} disabled={!selectedTrim}>Add Vehicle</button>
    </div>
  );
}

export default VehicleSearchMini;
