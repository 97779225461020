import React from 'react';
import { Box, Typography, Button } from '@mui/material';

// Sample mapping rules – these can later be fetched from the backend for configurability.
const mappingRules = [
  {
    // If the rating question with ID 'q1' is 4 or higher, recommend "Premium Service"
    conditions: [
      { questionId: 'q1', operator: 'gte', value: 4 },
    ],
    recommendation: 'Premium Service',
  },
  {
    // If the text question with ID 'q2' includes the word "low", recommend "Economy Package"
    conditions: [
      { questionId: 'q2', operator: 'includes', value: 'low' },
    ],
    recommendation: 'Economy Package',
  },
  {
    // If the checkbox question with ID 'q3' includes "Service A" and "Service B", recommend "Combined Service Package"
    conditions: [
      { questionId: 'q3', operator: 'includes', value: 'Service A' },
      { questionId: 'q3', operator: 'includes', value: 'Service B' },
    ],
    recommendation: 'Combined Service Package',
  },
];

// Helper function to evaluate a single condition.
function evaluateCondition(response, operator, value) {
  switch (operator) {
    case 'equals':
      return response == value;
    case 'gte':
      return parseFloat(response) >= parseFloat(value);
    case 'includes':
      // Check for array responses (checkbox) or string responses.
      if (Array.isArray(response)) {
        return response.includes(value);
      }
      return typeof response === 'string' && response.toLowerCase().includes(value.toLowerCase());
    default:
      return false;
  }
}

function Recommendations({ responses, onProceed }) {
  // Evaluate mapping rules based on responses.
  const recommendedServices = mappingRules.reduce((acc, rule) => {
    const conditionsMet = rule.conditions.every((cond) => {
      const response = responses[cond.questionId];
      return evaluateCondition(response, cond.operator, cond.value);
    });
    if (conditionsMet) {
      acc.push(rule.recommendation);
    }
    return acc;
  }, []);

  // Remove duplicate recommendations.
  const uniqueRecommendations = [...new Set(recommendedServices)];

  return (
    <Box sx={{ p: 4, backgroundColor: '#1C1C1C', color: '#C0C0C0' }}>
      <Typography variant="h4" gutterBottom>
        Recommended Services
      </Typography>
      {uniqueRecommendations.length > 0 ? (
        uniqueRecommendations.map((service, index) => (
          <Typography key={index} variant="h6" sx={{ mb: 2 }}>
            {service}
          </Typography>
        ))
      ) : (
        <Typography variant="body1" sx={{ mb: 2 }}>
          No specific recommendations based on your responses.
        </Typography>
      )}
      <Button
        variant="contained"
        sx={{ backgroundColor: '#39FF14', color: '#1C1C1C' }}
        onClick={onProceed}
      >
        Proceed with these recommendations
      </Button>
    </Box>
  );
}

export default Recommendations;
