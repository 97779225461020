// src/theme.js or inside ThemeContext.js (merged version)
import { createTheme } from '@mui/material/styles';

const basePalette = {
  primary: { main: '#39FF14', contrastText: '#1C1C1C' },
  secondary: { main: '#00FFFF', contrastText: '#1C1C1C' },
  background: {
    default: '#1C1C1C', // Matte Black
    paper: '#2F4F4F',   // Dark Slate Gray
  },
  text: {
    primary: '#FFFFFF',
    secondary: '#C0C0C0',
  },
};

const baseTypography = {
  fontFamily: 'Roboto, Arial, sans-serif',
  h4: { color: '#39FF14' },
  h6: { color: '#00FFFF' },
  body1: { color: '#C0C0C0' },
  body2: { color: '#FFFFFF' },
};

const highTechTheme = createTheme({
  palette: {
    mode: 'dark',
    ...basePalette,
  },
  typography: {
    ...baseTypography,
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: basePalette.background.paper,
          color: basePalette.text.primary,
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& label': {
            color: basePalette.text.secondary,
          },
          '& label.Mui-focused': {
            color: basePalette.primary.main,
          },
          '& .MuiInputBase-input': {
            color: basePalette.text.primary,
          },
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: basePalette.text.secondary,
            },
            '&:hover fieldset': {
              borderColor: basePalette.primary.main,
            },
            '&.Mui-focused fieldset': {
              borderColor: basePalette.primary.main,
            },
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: basePalette.text.secondary,
          '&.Mui-checked': {
            color: basePalette.primary.main,
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          borderRadius: '8px',
          color: basePalette.text.primary,
        },
        containedPrimary: {
          backgroundColor: basePalette.primary.main,
          color: basePalette.primary.contrastText,
          '&:hover': {
            backgroundColor: '#32CD32', // Lime Green on hover
          },
        },
        outlinedSecondary: {
          borderColor: basePalette.secondary.main,
          color: basePalette.secondary.main,
          '&:hover': {
            borderColor: '#00CED1', // Dark Turquoise on hover
            color: '#00CED1',
          },
        },
      },
    },
    // Additional component overrides:
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: basePalette.primary.main,
          '&:hover': {
            backgroundColor: 'rgba(57, 255, 20, 0.1)',
          },
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          backgroundColor: basePalette.background.paper,
          color: basePalette.text.primary,
          border: `1px solid ${basePalette.primary.main}`,
          '&:before': { display: 'none' },
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          padding: '0 16px',
        },
        content: {
          margin: '12px 0',
          '& .MuiTypography-root': {
            color: basePalette.text.primary,
          },
        },
      },
    },
  },
});

export default highTechTheme;
