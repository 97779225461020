import React, { useState, useEffect } from 'react';
import ServiceCard from '../ServiceCard';
import ManageServiceBrands from '../ServiceCrud/ManageServiceBrands';
import ServiceManagement from '../ServiceManagement/ServiceManagement';
import BrandManagement from '../../Brands/BrandManagement';
import axiosAppointmentInstance from '../../../utils/axiosAppointment';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './ServiceDashboard.css';

const ServiceDashboard = () => {
  const [services, setServices] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedService, setSelectedService] = useState(null);
  const [activeTab, setActiveTab] = useState('services'); // Track the selected tab

  // Fetch all services
  const fetchServices = async () => {
    try {
      setLoading(true);
      const response = await axiosAppointmentInstance.get('/services');
      setServices(response.data);
    } catch (error) {
      console.error('Error fetching services:', error);
      toast.error('Failed to load services.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchServices();
  }, []);

  return (
    <div className="service-dashboard">
      <div className="dashboard-header">
        <h2>Service Dashboard</h2>
      </div>

      {/* Tabs Navigation */}
      <div className="dashboard-tabs">
        <button
          className={activeTab === 'services' ? 'active' : ''}
          onClick={() => setActiveTab('services')}
        >
          Service Pricing
        </button>
        <button
          className={activeTab === 'brands' ? 'active' : ''}
          onClick={() => setActiveTab('brands')}
        >
          Brand Management
        </button>
        <button
          className={activeTab === 'management' ? 'active' : ''}
          onClick={() => setActiveTab('management')}
        >
          Service Management
        </button>
      </div>

      {/* Tab Content */}
      <div className="tab-content">
        {activeTab === 'services' && (
          <>
            {loading ? (
              <p className="loading-message">Loading services...</p>
            ) : services.length === 0 ? (
              <p className="no-services">No Services Available</p>
            ) : (
              <div className="service-cards-container">
                {services.map((service) => (
                  <ServiceCard 
                    key={service._id} 
                    service={service} 
                    onManageBrands={() => setSelectedService(service)} 
                    onRefresh={fetchServices} 
                  />
                ))}
              </div>
            )}
          </>
        )}

        {activeTab === 'brands' && <BrandManagement />}

        {activeTab === 'management' && <ServiceManagement />}
      </div>

      {selectedService && (
        <ManageServiceBrands
          service={selectedService}
          onClose={() => setSelectedService(null)}
          onRefresh={fetchServices}
        />
      )}
    </div>
  );
};

export default ServiceDashboard;
