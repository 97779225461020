import React, { useState, useEffect } from 'react';
import axiosInstance from '../../utils/axiosAppointment';
import BrandCard from '../Brands/BrandCard';
import ManageServiceBrands from '../Services/ServiceCrud/ManageServiceBrands';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './ServiceCard.css';

const ServiceCard = ({ service, serviceId, ownerId }) => {
  const [brands, setBrands] = useState([]);
  const [showManageBrands, setShowManageBrands] = useState(false);

  const fetchBrands = async () => {
    try {
      if (!service.brands || service.brands.length === 0) {
        setBrands([]);
        return;
      }

      // Fetch full details of each brand
      const response = await axiosInstance.get(`/services/${service._id}/brands`);
      setBrands(response.data); // Store full brand objects
    } catch (error) {
      console.error('Error fetching brand details:', error);
      toast.error('Failed to load brand details.');
    }
  };

  useEffect(() => {
    fetchBrands();
  }, [service._id]);

  return (
    <div className="service-card">
      <h3 className="service-title">{service.name}</h3>

      {/* Manage Service Brands Component */}
      <button className="manage-brands-btn" onClick={() => setShowManageBrands(true)}>
        Manage Brands for {service.name}
      </button>

      {showManageBrands && (
        <ManageServiceBrands
          service={service}
          onClose={() => setShowManageBrands(false)}
          onRefresh={fetchBrands} // <-- This ensures brands update after modification
        />
      )}

      {/* Display Attached Brands */}
      <div className="brand-list">
        {brands.length > 0 ? (
          brands.map((brand) => (
            <BrandCard key={brand._id} brandId={brand._id} serviceId={service._id} ownerId={service.owner} brandName={brand.name || "Unknown Brand"} />
          ))
        ) : (
          <p>No brands available</p>
        )}
      </div>
    </div>
  );
};

export default ServiceCard;
