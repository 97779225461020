import React, { useEffect, useState } from 'react';
import { useOwnerId } from '../../App'; // Assuming ownerId is provided via context
import { Button, Paper, Typography, Box } from '@mui/material';
import SchedulerWizard from './SchedulerWizard/SchedulerWizard'; // Existing direct flow
import DynamicQuestionnaire from './Questionnaire/DynamicQuestionnaire'; // Guided questionnaire
import Recommendations from './Questionnaire/Recommendations'; // New Recommendations component

function PublicScheduler() {
  const ownerId = useOwnerId(); // Get ownerId from context
  const [businessProfile, setBusinessProfile] = useState(null);
  const [loading, setLoading] = useState(true);
  // State for choosing the flow: null, "direct", or "guided"
  const [flowChoice, setFlowChoice] = useState(null);
  // Store responses from the guided questionnaire
  const [questionnaireResponses, setQuestionnaireResponses] = useState(null);

  useEffect(() => {
    if (!ownerId) {
      console.error('Owner ID is not set. Cannot fetch business profile.');
      setLoading(false);
      return;
    }

    const fetchBusinessProfile = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_SERVICE_SERVICE_URL}api/business-profile/public/${ownerId}`
        );

        if (!response.ok) {
          throw new Error(`Error fetching business profile: ${response.statusText}`);
        }

        const data = await response.json();
        setBusinessProfile(data);
      } catch (error) {
        console.error(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchBusinessProfile();
  }, [ownerId]);

  // Callback for when the guided questionnaire is completed
  const handleQuestionnaireComplete = (responses) => {
    console.log("Dynamic Questionnaire Responses:", responses);
    setQuestionnaireResponses(responses);
    // Here you can add additional mapping logic or API calls if needed.
  };

  // Handler to proceed after viewing recommendations
  const handleProceedWithRecommendations = () => {
    // For now, we transition to the direct flow.
    // Later, you might want to pass the recommendations into the SchedulerWizard.
    setFlowChoice("direct");
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!businessProfile) {
    return <div>Business profile not available at this time. Please try again later.</div>;
  }

  // Initial welcome and flow selection screen
  if (!flowChoice) {
    return (
      <Paper elevation={3} sx={{ p: 4, mt: 4, backgroundColor: '#1C1C1C', color: '#C0C0C0' }}>
        <Typography variant="h4" gutterBottom sx={{ color: '#39FF14' }}>
          Welcome to {businessProfile.businessName}
        </Typography>
        <Typography variant="body1" gutterBottom sx={{ color: '#C0C0C0' }}>
          Here on this page, you can get accurate pricing and appointment availability.
          Please choose how you would like to proceed:
        </Typography>
        <Box sx={{ mt: 4 }}>
          <Button
            variant="contained"
            sx={{ mr: 2, backgroundColor: '#39FF14', color: '#1C1C1C' }}
            onClick={() => setFlowChoice("direct")}
          >
            I know what I want
          </Button>
          <Button
            variant="contained"
            sx={{ backgroundColor: '#39FF14', color: '#1C1C1C' }}
            onClick={() => setFlowChoice("guided")}
          >
            Need Recommendations
          </Button>
        </Box>
      </Paper>
    );
  }

  // If the guided flow was chosen:
  if (flowChoice === "guided") {
    // If questionnaire responses haven't been submitted yet, render the questionnaire.
    if (!questionnaireResponses) {
      return <DynamicQuestionnaire onComplete={handleQuestionnaireComplete} />;
    }
    // Once responses are available, show the recommendations.
    return <Recommendations responses={questionnaireResponses} onProceed={handleProceedWithRecommendations} />;
  }

  // If the direct flow is chosen, render the existing SchedulerWizard.
  if (flowChoice === "direct") {
    return <SchedulerWizard ownerId={ownerId} />;
  }

  return null;
}

export default PublicScheduler;
