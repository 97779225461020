import React, { useState, useEffect } from 'react';
import axiosInstance from '../../utils/axios';
import {
  Typography,
  TextField,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemText,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Collapse
} from '@mui/material';
import { Add, Delete, Edit, Save, Cancel } from '@mui/icons-material';
import WindowTintOptions from './WindowTintOptions';
import WorkingTimeTable from './WorkingTimeTable';
import PPFOptions from './PPFOptions';
import './SubBrandManagement.css';

function SubBrandManagement({ brand, onBrandUpdate }) {
  const [subBrands, setSubBrands] = useState([]);
  const [services, setServices] = useState([]);
  // Panels are fetched for use in view mode (to display panel names)
  const [panels, setPanels] = useState([]);
  const [newSubBrand, setNewSubBrand] = useState({
    name: '',
    serviceId: ''
  });
  const [isEditingSubBrand, setIsEditingSubBrand] = useState(null);
  const [editSubBrandName, setEditSubBrandName] = useState('');
  const [editServiceId, setEditServiceId] = useState('');
  const [editDescription, setEditDescription] = useState('');
  const [uploadingLogo, setUploadingLogo] = useState(false);
  // Use radio group for subbrand type: "none", "windowTint", or "ppf"
  const [subBrandType, setSubBrandType] = useState('none');
  const [editTintOptions, setEditTintOptions] = useState([]);
  const [editPPFOptions, setEditPPFOptions] = useState([]);
  // Working time state – using only the table mode; always present but in a collapsible section
  const [isWorkingTime, setIsWorkingTime] = useState(false);
  const [editWorkingTimes, setEditWorkingTimes] = useState([]);
  const [showWorkingTimes, setShowWorkingTimes] = useState(false);

  useEffect(() => {
    fetchSubBrands();
    fetchServices();
    fetchPanels();
  }, [brand]);

  const fetchSubBrands = async () => {
    try {
      const response = await axiosInstance.get(`/brands/${brand._id}/sub-brands`);
      setSubBrands(response.data);
    } catch (error) {
      console.error('Error fetching sub-brands:', error);
    }
  };

  const fetchServices = async () => {
    try {
      const response = await axiosInstance.get('/services');
      setServices(response.data);
    } catch (error) {
      console.error('Error fetching services:', error);
    }
  };

  const fetchPanels = async () => {
    try {
      const response = await axiosInstance.get('/panels');
      setPanels(response.data);
    } catch (error) {
      console.error('Error fetching panels:', error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewSubBrand(prev => ({ ...prev, [name]: value }));
  };

  const handleAddSubBrand = async () => {
    if (!newSubBrand.name.trim()) return;
    try {
      const payload = {
        name: newSubBrand.name.trim(),
        serviceId: newSubBrand.serviceId || null,
        tintOptions: [],
        ppfOptions: [],
        workingTimes: []
      };
      const response = await axiosInstance.post(`/brands/${brand._id}/sub-brands`, payload);
      setSubBrands(prev => ([...prev, response.data]));
      setNewSubBrand({ name: '', serviceId: '' });
      onBrandUpdate();
    } catch (error) {
      console.error('Error adding sub-brand:', error);
    }
  };

  const handleUpdateSubBrand = async (subBrandId) => {
    if (!editSubBrandName.trim()) return;
    try {
      const payload = {
        name: editSubBrandName.trim(),
        serviceId: editServiceId || null,
        description: editDescription.trim(),
        tintOptions: subBrandType === 'windowTint' ? editTintOptions : [],
        isWindowTint: subBrandType === 'windowTint',
        ppfOptions: subBrandType === 'ppf' ? editPPFOptions : [],
        isPaintProtectionFilm: subBrandType === 'ppf',
        workingTimes: isWorkingTime ? editWorkingTimes : []
      };
      const response = await axiosInstance.put(`/brands/${brand._id}/sub-brands/${subBrandId}`, payload);
      setSubBrands(prev => prev.map(sb => sb._id === subBrandId ? response.data : sb));
      // Reset editing state
      setIsEditingSubBrand(null);
      setEditSubBrandName('');
      setEditServiceId('');
      setEditDescription('');
      setSubBrandType('none');
      setEditTintOptions([]);
      setEditPPFOptions([]);
      setIsWorkingTime(false);
      setEditWorkingTimes([]);
      setShowWorkingTimes(false);
      onBrandUpdate();
    } catch (error) {
      console.error('Error updating sub-brand:', error);
    }
  };

  const handleEditSubBrand = (subBrand) => {
    setIsEditingSubBrand(subBrand._id);
    setEditSubBrandName(subBrand.name);
    setEditServiceId(subBrand.serviceId || '');
    setEditDescription(subBrand.description || '');
    if (subBrand.tintOptions && subBrand.tintOptions.length > 0) {
      setSubBrandType('windowTint');
      setEditTintOptions(subBrand.tintOptions);
    } else if (subBrand.ppfOptions && subBrand.ppfOptions.length > 0) {
      setSubBrandType('ppf');
      setEditPPFOptions(subBrand.ppfOptions);
    } else {
      setSubBrandType('none');
    }
    if (subBrand.workingTimes && subBrand.workingTimes.length > 0) {
      setIsWorkingTime(true);
      setEditWorkingTimes(subBrand.workingTimes);
    } else {
      setIsWorkingTime(false);
      setEditWorkingTimes([]);
    }
    setShowWorkingTimes(false); // Collapsed by default when editing
  };

  const handleDeleteSubBrand = async (subBrandId) => {
    try {
      await axiosInstance.delete(`/brands/${brand._id}/sub-brands/${subBrandId}`);
      setSubBrands(prev => prev.filter(sb => sb._id !== subBrandId));
      onBrandUpdate();
    } catch (error) {
      console.error('Error deleting sub-brand:', error);
    }
  };

  const handleLogoChange = async (e, subBrandId) => {
    const file = e.target.files[0];
    if (!file) return;
    setUploadingLogo(true);
    try {
      const formData = new FormData();
      formData.append('logo', file);
      const response = await axiosInstance.put(
        `/brands/${brand._id}/sub-brands/${subBrandId}/logo`,
        formData,
        { headers: { 'Content-Type': 'multipart/form-data' } }
      );
      setSubBrands(prev => prev.map(sb => sb._id === subBrandId ? response.data.subBrand : sb));
    } catch (error) {
      console.error('Error uploading logo:', error);
    } finally {
      setUploadingLogo(false);
    }
  };

  const handleRemoveLogo = async (subBrandId) => {
    try {
      const response = await axiosInstance.put(
        `/brands/${brand._id}/sub-brands/${subBrandId}/logo/remove`
      );
      setSubBrands(prev => prev.map(sb => sb._id === subBrandId ? response.data.subBrand : sb));
    } catch (error) {
      console.error('Error removing logo:', error);
    }
  };

  return (
    <div className="sub-brand-management">
      <Typography variant="h6" align="center" className="sub-brand-heading">
        Sub-Brands for {brand.name}
      </Typography>

      <div className="sub-brand-form">
        <TextField
          label="Sub-Brand Name"
          variant="outlined"
          name="name"
          value={newSubBrand.name}
          onChange={handleInputChange}
          className="form-input"
        />
        <FormControl className="form-select">
          <InputLabel>Service</InputLabel>
          <Select
            name="serviceId"
            value={newSubBrand.serviceId}
            onChange={handleInputChange}
            label="Service"
          >
            <MenuItem value="">None</MenuItem>
            {services.map(service => (
              <MenuItem key={service._id} value={service._id}>
                {service.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Button variant="contained" color="primary" onClick={handleAddSubBrand} startIcon={<Add />}>
          Add Sub-Brand
        </Button>
      </div>

      <List className="sub-brand-list">
        {subBrands.map(subBrand => (
          <ListItem key={subBrand._id} className="sub-brand-list-item">
            {isEditingSubBrand === subBrand._id ? (
              <div className="sub-brand-edit">
                <TextField
                  variant="outlined"
                  value={editSubBrandName}
                  onChange={(e) => setEditSubBrandName(e.target.value)}
                  label="Sub-Brand Name"
                  className="edit-input"
                />
                <TextField
                  variant="outlined"
                  value={editDescription}
                  onChange={(e) => setEditDescription(e.target.value)}
                  label="Description"
                  className="edit-input"
                />
                <FormControl className="edit-select">
                  <InputLabel>Service</InputLabel>
                  <Select
                    value={editServiceId}
                    onChange={(e) => setEditServiceId(e.target.value)}
                    label="Service"
                  >
                    <MenuItem value="">None</MenuItem>
                    {services.map(service => (
                      <MenuItem key={service._id} value={service._id}>
                        {service.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl component="fieldset" className="edit-select">
                  <InputLabel shrink>Subbrand Type</InputLabel>
                  <RadioGroup
                    row
                    value={subBrandType}
                    onChange={(e) => {
                      const val = e.target.value;
                      setSubBrandType(val);
                      // Reset options when switching type
                      if (val === 'windowTint') {
                        setEditTintOptions([]);
                        setEditPPFOptions([]);
                      } else if (val === 'ppf') {
                        setEditPPFOptions([]);
                        setEditTintOptions([]);
                      } else {
                        setEditTintOptions([]);
                        setEditPPFOptions([]);
                      }
                    }}
                  >
                    <FormControlLabel value="none" control={<Radio />} label="None" />
                    <FormControlLabel value="windowTint" control={<Radio />} label="Window Tint" />
                    <FormControlLabel value="ppf" control={<Radio />} label="Paint Protection Film" />
                  </RadioGroup>
                </FormControl>
                {subBrandType === 'windowTint' && (
                  <WindowTintOptions tintOptions={editTintOptions} onUpdate={setEditTintOptions} />
                )}
                {subBrandType === 'ppf' && (
                  <PPFOptions ppfOptions={editPPFOptions} onUpdate={setEditPPFOptions} />
                )}
                <div className="collapsible-section">
                  <Button variant="outlined" onClick={() => setShowWorkingTimes(prev => !prev)}>
                    {showWorkingTimes ? 'Hide Working Times' : 'Show Working Times'}
                  </Button>
                  <Collapse in={showWorkingTimes}>
                    <WorkingTimeTable
                      serviceId={editServiceId}
                      initialWorkingTimes={editWorkingTimes}
                      onUpdate={setEditWorkingTimes}
                    />
                  </Collapse>
                </div>
                <div className="logo-section">
                  {subBrand.image ? (
                    <>
                      <img src={subBrand.image} alt="Logo" className="sub-brand-logo-large" />
                      <Button variant="outlined" component="label" disabled={uploadingLogo}>
                        Change Logo
                        <input type="file" hidden accept="image/*" onChange={(e) => handleLogoChange(e, subBrand._id)} />
                      </Button>
                      <Button variant="outlined" color="error" onClick={() => handleRemoveLogo(subBrand._id)} disabled={uploadingLogo}>
                        Remove Logo
                      </Button>
                    </>
                  ) : (
                    <Button variant="outlined" component="label" disabled={uploadingLogo}>
                      Upload Logo
                      <input type="file" hidden accept="image/*" onChange={(e) => handleLogoChange(e, subBrand._id)} />
                    </Button>
                  )}
                </div>
                <div className="edit-actions">
                  <IconButton onClick={() => handleUpdateSubBrand(subBrand._id)} color="primary">
                    <Save />
                  </IconButton>
                  <IconButton onClick={() => setIsEditingSubBrand(null)} color="secondary">
                    <Cancel />
                  </IconButton>
                </div>
              </div>
            ) : (
              <div className="sub-brand-view">
                <div className="sub-brand-info">
                  {subBrand.image && (
                    <img src={subBrand.image} alt="Logo" className="sub-brand-logo" />
                  )}
                  <ListItemText
                    primary={subBrand.name}
                    secondary={
                      <>
                        <span>Service: {services.find(s => s._id === subBrand.serviceId)?.name || 'None'}</span>
                        {subBrand.description && <div>Description: {subBrand.description}</div>}
                        {subBrand.tintOptions && subBrand.tintOptions.length > 0 && (
                          <div>
                            <strong>Tint Options:</strong> {subBrand.tintOptions.map((t, i) => (
                              <div key={i}>
                                {t.percentage}% — {t.rolls.length} roll(s)
                              </div>
                            ))} 
                          </div>
                        )}
                        {subBrand.workingTimes && subBrand.workingTimes.length > 0 && (
                          <div>
                            <strong>Working Times:</strong> {subBrand.workingTimes.map((wt, i) => (
                              <div key={i}>
                                Panel: {panels.find(p => p._id === wt.panel)?.name || wt.panel} - {wt.timeInMinutes} min
                              </div>
                            ))}
                          </div>
                        )}
                      </>
                    }
                  />
                </div>
                <div className="view-actions">
                  <IconButton onClick={() => handleEditSubBrand(subBrand)} color="primary">
                    <Edit />
                  </IconButton>
                  <IconButton onClick={() => handleDeleteSubBrand(subBrand._id)} color="secondary">
                    <Delete />
                  </IconButton>
                </div>
              </div>
            )}
          </ListItem>
        ))}
      </List>
    </div>
  );
}

export default SubBrandManagement;
