import React, { useState, useEffect } from "react";
import axiosInstance from "../../utils/axios";
import PricingSquareFoot from "../Pricing/PricingSquareFoot";
import PackageManagement from "../Pricing/PackageManagement"; // ✅ Import PackageManagement
import "./BrandCard.css";

const BrandCard = ({ brandId, serviceId, ownerId }) => {
  const [brandName, setBrandName] = useState("");

  useEffect(() => {
    if (!brandId) return;

    const fetchBrandName = async () => {
      try {
        const response = await axiosInstance.get(`/brands/${brandId}`);
        setBrandName(response.data?.name || "Unknown Brand");
      } catch (error) {
        console.error("Error fetching brand:", error);
      }
    };

    fetchBrandName();
  }, [brandId]);

  if (!brandId) {
    return <p className="error-message">Brand ID not found.</p>;
  }

  return (
    <div className="brand-card">
      <h3 className="brand-header">{brandName}</h3>

      {/* Pricing Table for Square Footage */}
      <PricingSquareFoot brandId={brandId} serviceId={serviceId} ownerId={ownerId} brandName={brandName} />

      {/* Ensure all required props are present before rendering PackageManagement */}
      {brandId && serviceId && ownerId && (
        <PackageManagement brandId={brandId} serviceId={serviceId} ownerId={ownerId} brandName={brandName} />
      )}
    </div>
  );
};

export default BrandCard;
