import React, { useState, useEffect } from 'react';
import axiosInstance from '../../../utils/axiosAppointment';
import AddBrand from '../../Brands/BrandCrud/AddBrand';
import { toast } from 'react-toastify';
import './ManageServiceBrands.css';

const ManageServiceBrands = ({ service, onRefresh, onClose }) => {
  const [associatedBrands, setAssociatedBrands] = useState([]);
  const [allBrands, setAllBrands] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState('');
  const [showAddBrandModal, setShowAddBrandModal] = useState(false);

  useEffect(() => {
    if (service?._id) {
      fetchAssociatedBrands();
      fetchAllBrands();
    }
  }, [service]);

  // Fetch brands linked to the service
  const fetchAssociatedBrands = async () => {
    try {
      const response = await axiosInstance.get(`/services/${service._id}/brands`);
      const brandDetails = await Promise.all(
        response.data.map(async (brand) => {
          try {
            const brandResponse = await axiosInstance.get(`/brands/${brand._id}`);
            return brandResponse.data;
          } catch (error) {
            console.error("Error fetching brand details:", error);
            return { _id: brand._id, name: "Unknown Brand" };
          }
        })
      );
      setAssociatedBrands(brandDetails);
    } catch (error) {
      console.error("Error fetching associated brands:", error);
    }
  };

  // Fetch all available brands
  const fetchAllBrands = async () => {
    try {
      const response = await axiosInstance.get(`/brands`);
      setAllBrands(response.data);
    } catch (error) {
      console.error('Error fetching brands:', error);
    }
  };

  // Add brand to service
  const handleAddBrand = async () => {
    if (!selectedBrand) {
      toast.error('Please select a brand');
      return;
    }

    try {
      await axiosInstance.put(`/services/${service._id}/add-brand/${selectedBrand}`);
      toast.success('Brand successfully added!');
      fetchAssociatedBrands();
      if (typeof onRefresh === 'function') {
        onRefresh();
      }
    } catch (error) {
      console.error('Error adding brand:', error.response?.data || error);
      toast.error('Failed to add brand.');
    }
  };

  // Remove brand from service
  const handleRemoveBrand = async (brandId) => {
    try {
      await axiosInstance.put(`/services/${service._id}/remove-brand/${brandId}`);
      toast.success("Brand successfully removed!");
      fetchAssociatedBrands();

      if (typeof onRefresh === "function") {
        onRefresh(); // Ensure it's a function before calling
      }
    } catch (error) {
      console.error("Error removing brand:", error.response?.data || error);
      toast.error("Failed to remove brand.");
    }
  };

  return (
    <div className="manage-service-brands">
      <h4>Manage Brands for {service.name}</h4>

      <ul className="associated-brands-list">
        {associatedBrands.length === 0 ? (
          <p>No brands associated with this service.</p>
        ) : (
          associatedBrands.map((brand) => (
            <li key={brand._id}>
              <strong>{brand.name || 'Unknown Brand'}</strong>
              <button className="remove-brand-button" onClick={() => handleRemoveBrand(brand._id)}>
                Remove
              </button>
            </li>
          ))
        )}
      </ul>

      <div className="add-brand-dropdown">
        <select value={selectedBrand} onChange={(e) => setSelectedBrand(e.target.value)}>
          <option value="">Select a Brand</option>
          {allBrands.map((brand) => (
            <option key={brand._id} value={brand._id}>
              {brand.name}
            </option>
          ))}
        </select>
        <button onClick={handleAddBrand}>Add</button>
        <button onClick={() => setShowAddBrandModal(true)}>Create Brand</button>
      </div>

      {showAddBrandModal && (
        <AddBrand
          onClose={() => {
            setShowAddBrandModal(false);
            fetchAllBrands();
          }}
          onRefresh={onRefresh}
        />
      )}

      <button className="close-manage-brands" onClick={onClose}>
        Close
      </button>
    </div>
  );
};

export default ManageServiceBrands;
