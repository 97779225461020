import React, { useState, useEffect } from "react";
import axios from "../../utils/axiosAppointment";
import DeleteConfirmationModal from "./DeleteConfirmationModal";
import EditPackage from "./EditPackage";
import "./ListPackages.css"; // Importing the new CSS file

const ListPackages = ({ serviceId, brandId }) => {
  const [packages, setPackages] = useState([]);
  const [subBrandPackages, setSubBrandPackages] = useState({});
  const [packageToEdit, setPackageToEdit] = useState(null);
  const [packageToDelete, setPackageToDelete] = useState(null);
  const [deleteModal, setDeleteModal] = useState(false);
  const [editModal, setEditModal] = useState(false);

  useEffect(() => {
    if (serviceId && brandId) {
      fetchPackages();
    }
  }, [serviceId, brandId]);

  const fetchPackages = async () => {
    try {
      const response = await axios.get(`/packages/service/${serviceId}/brand/${brandId}`);
      if (response.data) {
        const fetchedPackages = response.data;
  
        // 🔹 Fetch vehicle classifications to map names
        const classificationIds = [...new Set(fetchedPackages.map(pkg => pkg.vehicleClassificationId).filter(id => id))];
        let classificationMap = {};
  
        if (classificationIds.length > 0) {
          try {
            const classificationResponse = await axios.get(`/vehicle-classifications?ids=${classificationIds.join(",")}`);
            classificationMap = classificationResponse.data.reduce((acc, classification) => {
              acc[classification._id] = classification.name;
              return acc;
            }, {});
          } catch (error) {
            console.error("Error fetching vehicle classifications:", error);
          }
        }
  
        // 🔹 Assign Vehicle Classification Names
        const updatedPackages = fetchedPackages.map(pkg => ({
          ...pkg,
          vehicleClassificationName: classificationMap[pkg.vehicleClassificationId] || "N/A"
        }));
  
        setPackages(updatedPackages);
  
        // 🔹 Group Packages by SubBrandName
        const groupedPackages = updatedPackages.reduce((acc, pkg) => {
          if (!acc[pkg.subBrandName]) {
            acc[pkg.subBrandName] = [];
          }
          acc[pkg.subBrandName].push(pkg);
          return acc;
        }, {});
  
        setSubBrandPackages(groupedPackages);
      }
    } catch (error) {
      console.error("Error fetching packages:", error);
    }
  };

  const handleDelete = async () => {
    try {
      await axios.delete(`/packages/${packageToDelete}`);
      setDeleteModal(false);
      fetchPackages(); // Refresh List
    } catch (error) {
      console.error("Error deleting package:", error);
    }
  };

  return (
    <div className="list-packages-container">
      {Object.entries(subBrandPackages).map(([subBrandName, packages]) => (
        <div key={subBrandName} className="subbrand-package-table">
          <h3 className="subbrand-title">Packages for {subBrandName}</h3>
          <table className="package-table">
            <thead>
              <tr>
                <th>Package Details</th>
                {packages.map((pkg) => (
                  <th key={pkg._id}>
                    {pkg.name}
                    <span
                      className="edit-icon"
                      title="Edit Package"
                      onClick={() => {
                        if (pkg) {
                          console.log("Editing Package:", pkg);
                          setPackageToEdit(pkg);
                          setEditModal(true);
                        } else {
                          console.error("Package data is missing!");
                        }
                      }}
                    >
                      ✏️
                    </span>
                    <span
                      className="delete-icon"
                      title="Delete Package"
                      onClick={() => {
                        setPackageToDelete(pkg._id);
                        setDeleteModal(true);
                      }}
                    >
                      🗑️
                    </span>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="row-title">Price</td>
                {packages.map((pkg) => (
                  <td key={pkg._id}>${pkg.price}</td>
                ))}
              </tr>
              <tr>
                <td className="row-title">Description</td>
                {packages.map((pkg) => (
                  <td key={pkg._id}>{pkg.description}</td>
                ))}
              </tr>
              <tr>
                <td className="row-title">Panels</td>
                {packages.map((pkg) => (
                  <td key={pkg._id}>{pkg.panels.map(panel => panel.name).join(", ")}</td>
                ))}
              </tr>
              <tr>
  <td className="row-title">Vehicle Classification</td>
  {packages.map((pkg) => (
    <td key={pkg._id}>
      {pkg.vehicleClassificationName}
    </td>
  ))}
</tr>
            </tbody>
          </table>
        </div>
      ))}

      {deleteModal && (
        <DeleteConfirmationModal
          isOpen={deleteModal}
          onCancel={() => setDeleteModal(false)}
          onConfirm={handleDelete}
        />
      )}

      {editModal && (
        <EditPackage
          packageData={packageToEdit}
          onClose={() => setEditModal(false)}
          onPackageUpdated={fetchPackages} // Refresh List After Edit
        />
      )}
    </div>
  );
};

export default ListPackages;
