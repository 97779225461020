import React, { useState } from 'react';
import { 
  Box, 
  Typography, 
  TextField, 
  Button, 
  IconButton, 
  Checkbox,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel
} from '@mui/material';
import { Add, Delete } from '@mui/icons-material';

function WindowTintOptions({ tintOptions: initialOptions = [], onUpdate }) {
  const [options, setOptions] = useState(initialOptions);

  // Add a new tint option
  const handleAddTintOption = () => {
    const newOption = {
      percentage: '',
      visibleLightTransmission: '',
      totalSolarEnergyRejection: '',
      visibleLightReflectance: '',
      glareReduction: '',
      ultravioletProtection: '',
      irEnergyRejection: '',
      selectiveIRRejection: '',
      // New special order fields:
      isSpecialOrder: false,
      specialOrderCharge: { chargeType: '$', value: 0 },
      rolls: []
    };
    const updated = [...options, newOption];
    setOptions(updated);
    onUpdate(updated);
  };

  // Remove a tint option by index
  const handleRemoveTintOption = (index) => {
    const updated = options.filter((_, i) => i !== index);
    setOptions(updated);
    onUpdate(updated);
  };

  // Update a field in a tint option
  const handleOptionChange = (index, field, value) => {
    const updated = options.map((option, i) =>
      i === index ? { ...option, [field]: value } : option
    );
    setOptions(updated);
    onUpdate(updated);
  };

  // For updating nested specialOrderCharge fields
  const handleSpecialOrderChange = (index, field, value) => {
    const updated = options.map((option, i) => {
      if (i === index) {
        return { 
          ...option, 
          specialOrderCharge: { 
            ...option.specialOrderCharge, 
            [field]: value 
          } 
        };
      }
      return option;
    });
    setOptions(updated);
    onUpdate(updated);
  };

  // Add a roll entry to a tint option
  const handleAddRoll = (optionIndex) => {
    const newRoll = {
      width: '',
      totalLength: '',
      cost: '',
      inventory: ''
    };
    const updated = options.map((option, i) =>
      i === optionIndex ? { ...option, rolls: [...option.rolls, newRoll] } : option
    );
    setOptions(updated);
    onUpdate(updated);
  };

  // Update a field in a roll entry
  const handleRollChange = (optionIndex, rollIndex, field, value) => {
    const updated = options.map((option, i) => {
      if (i === optionIndex) {
        const updatedRolls = option.rolls.map((roll, j) =>
          j === rollIndex ? { ...roll, [field]: value } : roll
        );
        return { ...option, rolls: updatedRolls };
      }
      return option;
    });
    setOptions(updated);
    onUpdate(updated);
  };

  // Remove a roll entry from a tint option
  const handleRemoveRoll = (optionIndex, rollIndex) => {
    const updated = options.map((option, i) => {
      if (i === optionIndex) {
        const updatedRolls = option.rolls.filter((_, j) => j !== rollIndex);
        return { ...option, rolls: updatedRolls };
      }
      return option;
    });
    setOptions(updated);
    onUpdate(updated);
  };

  return (
    <Box sx={{ mt: 2 }}>
      <Typography variant="h6">Window Tint Options</Typography>
      {options.map((option, index) => (
        <Box 
          key={index} 
          sx={{ border: '1px solid #ccc', borderRadius: '8px', p: 2, mb: 2 }}
        >
          <Box sx={{ display: 'flex', gap: 2, mb: 2, flexWrap: 'wrap' }}>
            <TextField
              label="Tint Percentage (%)"
              value={option.percentage}
              onChange={(e) => handleOptionChange(index, 'percentage', e.target.value)}
              type="number"
              sx={{ minWidth: 150 }}
            />
            <TextField
              label="% Visible Light Transmission"
              value={option.visibleLightTransmission}
              onChange={(e) => handleOptionChange(index, 'visibleLightTransmission', e.target.value)}
              type="number"
              sx={{ minWidth: 150 }}
            />
            <TextField
              label="% Total Solar Energy Rejection"
              value={option.totalSolarEnergyRejection}
              onChange={(e) => handleOptionChange(index, 'totalSolarEnergyRejection', e.target.value)}
              type="number"
              sx={{ minWidth: 150 }}
            />
          </Box>
          <Box sx={{ display: 'flex', gap: 2, mb: 2, flexWrap: 'wrap' }}>
            <TextField
              label="% Visible Light Reflectance"
              value={option.visibleLightReflectance}
              onChange={(e) => handleOptionChange(index, 'visibleLightReflectance', e.target.value)}
              type="number"
              sx={{ minWidth: 150 }}
            />
            <TextField
              label="% Glare Reduction"
              value={option.glareReduction}
              onChange={(e) => handleOptionChange(index, 'glareReduction', e.target.value)}
              type="number"
              sx={{ minWidth: 150 }}
            />
            <TextField
              label="% Ultraviolet Protection"
              value={option.ultravioletProtection}
              onChange={(e) => handleOptionChange(index, 'ultravioletProtection', e.target.value)}
              type="number"
              sx={{ minWidth: 150 }}
            />
          </Box>
          <Box sx={{ display: 'flex', gap: 2, mb: 2, flexWrap: 'wrap' }}>
            <TextField
              label="% IR Energy Rejection"
              value={option.irEnergyRejection}
              onChange={(e) => handleOptionChange(index, 'irEnergyRejection', e.target.value)}
              type="number"
              sx={{ minWidth: 150 }}
            />
            <TextField
              label="% Selective IR Rejection"
              value={option.selectiveIRRejection}
              onChange={(e) => handleOptionChange(index, 'selectiveIRRejection', e.target.value)}
              type="number"
              sx={{ minWidth: 150 }}
            />
          </Box>
          {/* Special Order Section */}
          <Box sx={{ display: 'flex', gap: 2, alignItems: 'center', mb: 2 }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={option.isSpecialOrder || false}
                  onChange={(e) => handleOptionChange(index, 'isSpecialOrder', e.target.checked)}
                  color="primary"
                />
              }
              label="Special Order"
            />
          </Box>
          {option.isSpecialOrder && (
            <Box sx={{ display: 'flex', gap: 2, mb: 2, flexWrap: 'wrap', alignItems: 'center' }}>
              <FormControl sx={{ minWidth: 100 }}>
                <InputLabel>Charge Type</InputLabel>
                <Select
                  value={option.specialOrderCharge?.chargeType || '$'}
                  onChange={(e) => handleSpecialOrderChange(index, 'chargeType', e.target.value)}
                  label="Charge Type"
                >
                  <MenuItem value="$">$</MenuItem>
                  <MenuItem value="%">%</MenuItem>
                </Select>
              </FormControl>
              <TextField
                label="Charge Amount"
                type="number"
                value={option.specialOrderCharge?.value || 0}
                onChange={(e) => handleSpecialOrderChange(index, 'value', e.target.value)}
                sx={{ minWidth: 100 }}
              />
            </Box>
          )}
          {/* Rolls Section */}
          <Box sx={{ mt: 2 }}>
            <Typography variant="subtitle1">Rolls</Typography>
            {option.rolls.map((roll, rIndex) => (
              <Box 
                key={rIndex} 
                sx={{ display: 'flex', gap: 2, mb: 1, alignItems: 'center', flexWrap: 'wrap' }}
              >
                <TextField
                  label="Width (in)"
                  value={roll.width}
                  onChange={(e) => handleRollChange(index, rIndex, 'width', e.target.value)}
                  type="number"
                  sx={{ minWidth: 100 }}
                />
                <TextField
                  label="Length (ft)"
                  value={roll.totalLength}
                  onChange={(e) => handleRollChange(index, rIndex, 'totalLength', e.target.value)}
                  type="number"
                  sx={{ minWidth: 100 }}
                />
                <TextField
                  label="Cost"
                  value={roll.cost}
                  onChange={(e) => handleRollChange(index, rIndex, 'cost', e.target.value)}
                  type="number"
                  sx={{ minWidth: 100 }}
                />
                <TextField
                  label="Inventory"
                  value={roll.inventory}
                  onChange={(e) => handleRollChange(index, rIndex, 'inventory', e.target.value)}
                  type="number"
                  sx={{ minWidth: 100 }}
                />
                <IconButton onClick={() => handleRemoveRoll(index, rIndex)} color="error">
                  <Delete />
                </IconButton>
              </Box>
            ))}
            <Button 
              variant="outlined" 
              onClick={() => handleAddRoll(index)} 
              startIcon={<Add />}
              sx={{ mt: 1 }}
            >
              Add Roll
            </Button>
          </Box>
          <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
            <Button 
              variant="outlined" 
              color="error" 
              onClick={() => handleRemoveTintOption(index)} 
              startIcon={<Delete />}
            >
              Remove Tint Option
            </Button>
          </Box>
        </Box>
      ))}
      <Button variant="contained" onClick={handleAddTintOption} startIcon={<Add />}>
        Add Tint Option
      </Button>
    </Box>
  );
}

export default WindowTintOptions;
