import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import axiosInstance from '../../../utils/axiosAppointment';
import { useOwnerId } from '../../../App';

function MappingRulesBuilder() {
  const ownerId = useOwnerId();
  const [availableQuestions, setAvailableQuestions] = useState([]);
  const [services, setServices] = useState([]);
  // selectedQuestionId stores the string version of the question's _id
  const [selectedQuestionId, setSelectedQuestionId] = useState('');
  // mappingRules: one rule per answer option of the selected question:
  // { questionId, answer, optionId, recommendation: { serviceId, subbrandId } }
  const [mappingRules, setMappingRules] = useState([]);

  // Fetch available questions from the questionnaire configuration
  useEffect(() => {
    async function fetchQuestions() {
      try {
        const response = await axiosInstance.get('/questionnaire', {
          params: { ownerId },
        });
        // Filter for questions that have options (e.g. checkbox type)
        const questionsWithOptions = (response.data.questions || []).filter(
          (q) => q.type === 'checkbox' && q.options && q.options.length > 0
        );
        setAvailableQuestions(questionsWithOptions);
      } catch (error) {
        console.error('Error fetching available questions:', error);
      }
    }
    if (ownerId) {
      fetchQuestions();
    }
  }, [ownerId]);

  // Fetch available services (assuming this route returns services with populated brands and subBrands)
  useEffect(() => {
    async function fetchServices() {
      try {
        const response = await axiosInstance.get('/services');
        setServices(response.data);
      } catch (error) {
        console.error('Error fetching services:', error);
      }
    }
    fetchServices();
  }, []);

  // When a question is selected, auto-populate mapping rules for each answer option.
  useEffect(() => {
    if (selectedQuestionId) {
      const selectedQuestion = availableQuestions.find(
        (q) => (q._id || q.id)?.toString() === selectedQuestionId
      );
      if (selectedQuestion) {
        // Create one mapping rule per answer option
        const newRules = selectedQuestion.options.map((opt) => ({
          questionId: (selectedQuestion._id || selectedQuestion.id)?.toString(),
          answer: opt.label,
          optionId: (opt._id || opt.id)?.toString(),
          recommendation: { serviceId: '', subbrandId: '' },
        }));
        setMappingRules(newRules);
      }
    } else {
      setMappingRules([]);
    }
  }, [selectedQuestionId, availableQuestions]);

  // Helper: For a given service, flatten subbrands from all its brands.
  const getSubbrandsForService = (serviceId) => {
    const service = services.find((s) => s._id === serviceId);
    if (!service || !service.brands) return [];
    let allSubbrands = [];
    service.brands.forEach((brand) => {
      // Try both "subBrands" and "subbrands"
      const subs = brand.subBrands || brand.subbrands;
      if (subs && Array.isArray(subs)) {
        allSubbrands = allSubbrands.concat(subs);
      }
    });
    // Filter out any subbrand that doesn't have an id
    return allSubbrands.filter((sub) => sub._id || sub.id);
  };

  // Handler for question selection change
  const handleQuestionSelect = (e) => {
    setSelectedQuestionId(e.target.value);
  };

  // Update recommended service for a mapping rule at index i
  const handleRecommendationServiceChange = (index, serviceId) => {
    setMappingRules((prev) =>
      prev.map((rule, idx) =>
        idx === index
          ? { ...rule, recommendation: { serviceId, subbrandId: '' } }
          : rule
      )
    );
  };

  // Update recommended subbrand for a mapping rule at index i
  const handleRecommendationSubbrandChange = (index, subbrandId) => {
    setMappingRules((prev) =>
      prev.map((rule, idx) =>
        idx === index
          ? { ...rule, recommendation: { ...rule.recommendation, subbrandId } }
          : rule
      )
    );
  };

  // Save mapping rules to the backend
  const handleSaveMappingRules = async () => {
    try {
      await axiosInstance.post(
        '/recommendation-mappings',
        { rules: mappingRules },
        { params: { ownerId } }
      );
      alert('Mapping rules saved successfully!');
    } catch (error) {
      console.error('Error saving mapping rules:', error);
      alert('Error saving mapping rules.');
    }
  };

  return (
    <Box sx={{ p: 4, backgroundColor: '#1C1C1C', color: '#C0C0C0' }}>
      <Typography variant="h4" gutterBottom>
        Recommendation Mapping Rules
      </Typography>
      <FormControl fullWidth sx={{ my: 2 }}>
        <InputLabel sx={{ color: '#C0C0C0' }}>Select Question</InputLabel>
        <Select
          value={selectedQuestionId}
          label="Select Question"
          onChange={handleQuestionSelect}
          sx={{ color: '#C0C0C0', backgroundColor: '#333' }}
        >
          {availableQuestions.map((q) => (
            <MenuItem key={(q._id || q.id)?.toString()} value={(q._id || q.id)?.toString()}>
              {q.question}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {selectedQuestionId && mappingRules.length > 0 && (
        <>
          <Typography variant="h6" sx={{ my: 2 }}>
            Map Recommendations for Each Answer
          </Typography>
          {mappingRules.map((rule, index) => (
            <Box
              key={index}
              sx={{ mb: 2, p: 2, border: '1px solid #39FF14', borderRadius: 2 }}
            >
              <Typography>
                <strong>Answer:</strong> {rule.answer}
              </Typography>
              <FormControl fullWidth sx={{ my: 1 }}>
                <InputLabel sx={{ color: '#C0C0C0' }}>
                  Recommended Service
                </InputLabel>
                <Select
                  value={rule.recommendation.serviceId}
                  label="Recommended Service"
                  onChange={(e) =>
                    handleRecommendationServiceChange(index, e.target.value)
                  }
                  sx={{ color: '#C0C0C0', backgroundColor: '#333' }}
                >
                  {services.map((service) => (
                    <MenuItem key={service._id} value={service._id}>
                      {service.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {rule.recommendation.serviceId && (
                <FormControl fullWidth sx={{ my: 1 }}>
                  <InputLabel sx={{ color: '#C0C0C0' }}>
                    Recommended Subbrand
                  </InputLabel>
                  <Select
                    value={rule.recommendation.subbrandId}
                    label="Recommended Subbrand"
                    onChange={(e) =>
                      handleRecommendationSubbrandChange(index, e.target.value)
                    }
                    sx={{ color: '#C0C0C0', backgroundColor: '#333' }}
                  >
                    {getSubbrandsForService(rule.recommendation.serviceId).map(
                      (subbrand) => (
                        <MenuItem
                          key={(subbrand._id || subbrand.id)?.toString()}
                          value={(subbrand._id || subbrand.id)?.toString()}
                        >
                          {subbrand.name}
                        </MenuItem>
                      )
                    )}
                  </Select>
                </FormControl>
              )}
            </Box>
          ))}
        </>
      )}
      <Button
        variant="contained"
        onClick={handleSaveMappingRules}
        sx={{ backgroundColor: '#39FF14', color: '#1C1C1C', mt: 2 }}
      >
        Save Mapping Rules
      </Button>
    </Box>
  );
}

export default MappingRulesBuilder;
