import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Typography,
  Link,
  Tabs,
  Tab,
  Grid,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Divider,
} from '@mui/material';
import axiosInstance from '../../../utils/axiosAppointment';
import axiosVehicle from '../../../utils/axiosVehicle';
import DateAndTimeSelection from './DateAndTimeSelection';
import CustomerInformationEntry from './CustomerInformationEntry';
import BookingSummary from './BookingSummary';
import VehicleSearchMini from "../../VehicleSearch/VehicleSearchMini";
import './PublicSchedulerWizard.css';

function PublicSchedulerWizard({ onBack }) {
  // Existing states
  const [services, setServices] = useState([]);
  const [selectedVehicle, setSelectedVehicle] = useState(null);
  const [vehicleClassification, setVehicleClassification] = useState(null);
  const [showVehicleSearch, setShowVehicleSearch] = useState(false);
  
  // New states for the new flow
  const [activeService, setActiveService] = useState(null);
  const [panelsByService, setPanelsByService] = useState({}); // { serviceId: [panels] }
  const [selectedPanels, setSelectedPanels] = useState({}); // { serviceId: [panelIds] }
  const [recommendedPackages, setRecommendedPackages] = useState([]);
  const [appointmentPreview, setAppointmentPreview] = useState([]);

  // Fetch services on mount
  useEffect(() => {
    fetchServices();
  }, []);

  // When activeService or vehicleClassification changes, fetch panels if needed
  useEffect(() => {
    if (activeService && vehicleClassification && !panelsByService[activeService]) {
      fetchPanelsForService(activeService);
    }
  }, [activeService, vehicleClassification]);

  const fetchServices = async () => {
    try {
      const response = await axiosInstance.get('/services');
      setServices(response.data);
      if (response.data.length > 0) {
        // Set the first service as active by default
        setActiveService(response.data[0]._id);
      }
    } catch (error) {
      console.error("Error fetching services:", error);
    }
  };

  // Vehicle selection handler
  const handleVehicleSelection = (vehicle) => {
    if (!vehicle) return;
    setSelectedVehicle(vehicle);
    setVehicleClassification(vehicle.classification || null);
    setShowVehicleSearch(false);
    // Clear any previously fetched panels as the classification might have changed
    setPanelsByService({});
    setSelectedPanels({});
  };

  const handleVehicleChange = () => {
    setSelectedVehicle(null);
    setVehicleClassification(null);
    setShowVehicleSearch(false);
    setPanelsByService({});
    setSelectedPanels({});
  };

  // Fetch panels for the given service and filter by vehicle classification
  const fetchPanelsForService = async (serviceId) => {
    if (!vehicleClassification) return;
    try {
      const response = await axiosInstance.get(`/panels/service/${serviceId}`);
      const filteredPanels = response.data.filter(panel => {
        if (panel.defaultClassifications && Array.isArray(panel.defaultClassifications)) {
          return panel.defaultClassifications
            .map(cls => cls._id ? cls._id.toString() : cls.toString())
            .includes(vehicleClassification.toString());
        }
        return false;
      });
      setPanelsByService(prev => ({ ...prev, [serviceId]: filteredPanels }));
    } catch (error) {
      console.error("Error fetching panels for service:", error);
    }
  };

  // Toggle selection of a panel for a service
  const togglePanelSelection = (serviceId, panelId) => {
    const idStr = panelId.toString();
    setSelectedPanels(prev => {
      const currentSelection = prev[serviceId] || [];
      const isSelected = currentSelection.includes(idStr);
      let updatedSelection;
      if (isSelected) {
        updatedSelection = currentSelection.filter(id => id !== idStr);
      } else {
        updatedSelection = [...currentSelection, idStr];
      }
      return { ...prev, [serviceId]: updatedSelection };
    });
  };

  // Submit button handler to fetch recommended packages based on active service, vehicle classification, and selected panels
  const handleSubmitPanels = async () => {
    if (!activeService || !vehicleClassification) return;
    const panels = selectedPanels[activeService] || [];
    try {
      const response = await axiosInstance.get(`/packages/recommended`, {
        params: {
          serviceId: activeService,
          vehicleClassificationId: vehicleClassification,
          panels: panels.join(',') // sending panel ids as a comma-separated list
        }
      });
      setRecommendedPackages(response.data);
    } catch (error) {
      console.error("Error fetching recommended packages:", error);
    }
  };

  // Handle adding a package to the appointment preview sidebar
  const handleAddPackage = (pkg) => {
    setAppointmentPreview(prev => {
      if (prev.some(item => item._id === pkg._id)) return prev;
      return [...prev, pkg];
    });
  };

  // Handle removal of a package from the appointment preview
  const handleRemovePackage = (pkgId) => {
    setAppointmentPreview(prev => prev.filter(pkg => pkg._id !== pkgId));
  };

  return (
    <Box className="publicSchedulerWizard">
      {/* Breadcrumbs */}
      <Box className="publicSchedulerBreadcrumbs">
        <Link onClick={onBack}>Back</Link>
        <Typography variant="h6" component="span" className="stepTitle">
          Step 1: Service Selection
        </Typography>
      </Box>

      {/* Vehicle Selection Area */}
      <Box className="vehicleSelection">
        {!selectedVehicle ? (
          <Box>
            <Button variant="contained" onClick={() => setShowVehicleSearch(!showVehicleSearch)} className="vehicleSelectionButton">
              Select Vehicle
            </Button>
            {showVehicleSearch && (
              <VehicleSearchMini onVehicleAdd={handleVehicleSelection} />
            )}
          </Box>
        ) : (
          <Box className="selectedVehicleDisplay">
            <Typography variant="h6" className="vehicleText">
              Selected Vehicle: {selectedVehicle.year} {selectedVehicle.make} {selectedVehicle.model}
            </Typography>
            <Link onClick={handleVehicleChange} className="changeLink">Change Vehicle</Link>
          </Box>
        )}
      </Box>

      {/* Main Layout: Left - Service/Panel Selection & Recommended Packages Table, Right - Appointment Preview */}
      <Grid container spacing={2} className="mainLayout">
        {/* Left Column */}
        <Grid item xs={8} className="leftColumn">
          {/* Service Tabs */}
          <Tabs
            value={activeService}
            onChange={(e, newValue) => setActiveService(newValue)}
            variant="scrollable"
            scrollButtons="auto"
            textColor="inherit"
            indicatorColor="secondary"
            className="serviceTabs"
          >
            {services.map(service => (
              <Tab
                key={service._id}
                value={service._id}
                label={service.name}
                className={`serviceTab ${activeService === service._id ? 'active' : ''}`}
              />
            ))}
          </Tabs>

          {/* Panel Selection for Active Service */}
          {activeService && (
            <Box className="panelSelection">
              {services.find(s => s._id === activeService) && (
                <>
                  <Typography variant="h5" className="panelSelectionHeader">
                    {services.find(s => s._id === activeService).name}
                  </Typography>
                  <Typography variant="subtitle1" className="panelSelectionSubHeader">
                    please select panels for {services.find(s => s._id === activeService).name}
                  </Typography>
                </>
              )}
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                {(panelsByService[activeService] || []).map(panel => {
                  const panelIdStr = panel._id.toString();
                  const isSelected = (selectedPanels[activeService] || []).includes(panelIdStr);
                  return (
                    <Button
                      key={panelIdStr}
                      onClick={() => togglePanelSelection(activeService, panel._id)}
                      className={`panelButton ${isSelected ? 'selected' : ''}`}
                    >
                      {panel.name}
                    </Button>
                  );
                })}
              </Box>
              <Button variant="contained" onClick={handleSubmitPanels} className="submitButton">
                Submit
              </Button>
            </Box>
          )}

          {/* Recommended Packages Table (clickable rows to add to preview) */}
          {recommendedPackages.length > 0 && (
            <Box className="recommendedPackagesTable">
              <Typography variant="h6" className="recommendedPackagesHeader">
                Recommended Packages (Click to add)
              </Typography>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ color: '#00FFFF' }}>Name</TableCell>
                    <TableCell sx={{ color: '#00FFFF' }}>Brand</TableCell>
                    <TableCell sx={{ color: '#00FFFF' }}>Sub-Brand</TableCell>
                    <TableCell sx={{ color: '#00FFFF' }}>Description</TableCell>
                    <TableCell sx={{ color: '#00FFFF' }}>Price</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {recommendedPackages.map(pkg => (
                    <TableRow key={pkg._id} onClick={() => handleAddPackage(pkg)} sx={{ cursor: 'pointer' }}>
                      <TableCell>{pkg.name}</TableCell>
                      <TableCell>{pkg.brandName || '-'}</TableCell>
                      <TableCell>{pkg.subBrandName || '-'}</TableCell>
                      <TableCell>{pkg.description || '-'}</TableCell>
                      <TableCell>${pkg.price.toFixed(2)}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          )}
        </Grid>

        {/* Right Column - Appointment Preview */}
        <Grid item xs={4} className="rightColumn">
          <Box className="appointmentPreview">
            <Typography variant="h6" sx={{ color: '#00FFFF', mb: 1 }}>
              Appointment Preview
            </Typography>
            {appointmentPreview.length > 0 ? (
              appointmentPreview.map(pkg => (
                <Box key={pkg._id} className="appointmentPreviewItem">
                  <Typography variant="subtitle1" sx={{ color: '#39FF14' }}>
                    {pkg.name}
                  </Typography>
                  <Typography variant="body2">
                    {pkg.serviceName} - {pkg.brandName}
                  </Typography>
                  <Typography variant="body2">
                    ${pkg.price.toFixed(2)}
                  </Typography>
                  <Button
                    onClick={() => handleRemovePackage(pkg._id)}
                    className="removeButton"
                  >
                    X
                  </Button>
                </Box>
              ))
            ) : (
              <Typography variant="body2">No packages added.</Typography>
            )}
          </Box>
        </Grid>
      </Grid>

      <Divider sx={{ mt: 4, mb: 2, backgroundColor: '#00FFFF' }} />
      <Typography variant="caption" className="footerNote">
        Note: Only packages added to the Appointment Preview will be saved and moved to the next step.
      </Typography>
    </Box>
  );
}

export default PublicSchedulerWizard;
