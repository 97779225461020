import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  TextField,
  Select,
  MenuItem,
  Typography,
  FormControl,
  InputLabel,
  IconButton,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import axiosInstance from '../../../utils/axiosAppointment';
import { useOwnerId } from '../../../App';

const questionTypes = [
  { value: 'rating', label: 'Rating (1-5)' },
  { value: 'text', label: 'Text' },
  { value: 'checkbox', label: 'Checkbox' },
];

// Fully controlled OptionMapping component—does not maintain its own state.
function OptionMapping({ option, services, onMappingChange }) {
  // When the service selection changes, notify parent with new mapping.
  const handleServiceChange = (e) => {
    const newServiceId = e.target.value;
    onMappingChange({ serviceId: newServiceId, subbrandId: '' });
  };

  // When subbrand selection changes, notify parent.
  const handleSubbrandChange = (e) => {
    const newSubbrandId = e.target.value;
    onMappingChange({ serviceId: option.mapping.serviceId, subbrandId: newSubbrandId });
  };

  // Helper: For a given service, flatten subbrands from all its brands.
  const getSubbrandsForService = (serviceId) => {
    const service = services.find((s) => s._id === serviceId);
    if (!service || !service.brands) return [];
    let allSubbrands = [];
    service.brands.forEach((brand) => {
      const subs = brand.subBrands || brand.subbrands;
      if (subs && Array.isArray(subs)) {
        allSubbrands = allSubbrands.concat(subs);
      }
    });
    return allSubbrands.filter((sub) => sub._id || sub.id);
  };

  return (
    <Box sx={{ ml: 4, mt: 1 }}>
      <FormControl fullWidth sx={{ my: 1 }}>
        <InputLabel sx={{ color: '#C0C0C0' }}>Recommended Service</InputLabel>
        <Select
          value={option.mapping.serviceId}
          label="Recommended Service"
          onChange={handleServiceChange}
          sx={{ color: '#C0C0C0', backgroundColor: '#333' }}
        >
          {services.map((service) => (
            <MenuItem key={service._id} value={service._id}>
              {service.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {option.mapping.serviceId && (
        <>
          <FormControl fullWidth sx={{ my: 1 }}>
            <InputLabel sx={{ color: '#C0C0C0' }}>Recommended Subbrand</InputLabel>
            <Select
              value={option.mapping.subbrandId}
              label="Recommended Subbrand"
              onChange={handleSubbrandChange}
              sx={{ color: '#C0C0C0', backgroundColor: '#333' }}
            >
              {getSubbrandsForService(option.mapping.serviceId).map((subbrand) => (
                <MenuItem
                  key={(subbrand._id || subbrand.id)?.toString()}
                  value={(subbrand._id || subbrand.id)?.toString()}
                >
                  {subbrand.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Button variant="outlined" sx={{ mt: 1 }} onClick={() => alert("Recommend Package/Subbrand placeholder")}>
            Recommend Package/Subbrand
          </Button>
        </>
      )}
    </Box>
  );
}

function QuestionnaireBuilder() {
  const [questions, setQuestions] = useState([]);
  const ownerId = useOwnerId();

  // Fetch questionnaire configuration and deep clone to ensure independent objects.
  useEffect(() => {
    async function fetchQuestions() {
      try {
        const response = await axiosInstance.get('/questionnaire', {
          params: { ownerId },
        });
        const fetched = response.data.questions || [];
        // Deep clone to avoid sharing references.
        setQuestions(JSON.parse(JSON.stringify(fetched)));
      } catch (error) {
        console.error('Error fetching questionnaire configuration:', error);
      }
    }
    if (ownerId) {
      fetchQuestions();
    }
  }, [ownerId]);

  // Fetch available services for mapping.
  const [services, setServices] = useState([]);
  useEffect(() => {
    async function fetchServices() {
      try {
        const response = await axiosInstance.get('/services');
        setServices(response.data);
      } catch (error) {
        console.error('Error fetching services:', error);
      }
    }
    fetchServices();
  }, []);

  // Handler to add a new question.
  const handleAddQuestion = () => {
    setQuestions((prev) => [
      ...prev,
      { id: `${Date.now()}-${Math.random()}`, question: '', type: 'text', options: [] },
    ]);
  };

  // Handler for changes in question text or type.
  const handleQuestionChange = (id, field, value) => {
    setQuestions((prev) =>
      prev.map((q) => (q.id === id ? { ...q, [field]: value } : q))
    );
  };

  // Handler to add an option; for checkbox questions, add a default empty mapping.
  const handleAddOption = (questionId) => {
    setQuestions((prev) =>
      prev.map((q) => {
        if (q.id === questionId) {
          const newOption = {
            id: `${Date.now()}-${Math.random()}`,
            label: '',
            mapping: { serviceId: '', subbrandId: '' },
          };
          return { ...q, options: [...(q.options || []), newOption] };
        }
        return q;
      })
    );
  };

  // Handler for changes in an option's label.
  const handleOptionChange = (questionId, optionId, value) => {
    setQuestions((prev) =>
      prev.map((q) => {
        if (q.id === questionId) {
          const updatedOptions = q.options.map((opt) =>
            opt.id === optionId ? { ...opt, label: value } : opt
          );
          return { ...q, options: updatedOptions };
        }
        return q;
      })
    );
  };

  // Handler to delete a question.
  const handleDeleteQuestion = (questionId) => {
    setQuestions((prev) => prev.filter((q) => q.id !== questionId));
  };

  // Handler to delete an option from a question.
  const handleDeleteOption = (questionId, optionId) => {
    setQuestions((prev) =>
      prev.map((q) => {
        if (q.id === questionId) {
          const updatedOptions = q.options.filter((opt) => opt.id !== optionId);
          return { ...q, options: updatedOptions };
        }
        return q;
      })
    );
  };

  // Callback from OptionMapping to update an option's mapping.
  const updateOptionMapping = (questionId, optionId, newMapping) => {
    setQuestions((prev) =>
      prev.map((q) => {
        if (q.id === questionId) {
          const updatedOptions = q.options.map((opt) =>
            opt.id === optionId ? { ...opt, mapping: newMapping } : opt
          );
          return { ...q, options: updatedOptions };
        }
        return q;
      })
    );
  };

  // Handler to save the questionnaire configuration.
  const handleSaveConfiguration = async () => {
    try {
      await axiosInstance.post(
        '/questionnaire',
        { questions },
        { params: { ownerId } }
      );
      alert('Questionnaire configuration saved successfully!');
    } catch (error) {
      console.error('Error saving configuration:', error);
      alert('Error saving configuration.');
    }
  };

  return (
    <Box sx={{ p: 4, backgroundColor: '#1C1C1C', color: '#C0C0C0' }}>
      <Typography variant="h4" gutterBottom>
        Questionnaire Builder
      </Typography>
      {questions.map((q) => (
        <Box key={q.id} sx={{ mb: 3, border: '1px solid #39FF14', p: 2, borderRadius: 2 }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <TextField
              label="Question"
              variant="outlined"
              value={q.question}
              onChange={(e) => handleQuestionChange(q.id, 'question', e.target.value)}
              fullWidth
              sx={{ mb: 2, input: { color: '#C0C0C0' } }}
            />
            <IconButton onClick={() => handleDeleteQuestion(q.id)} color="error">
              <DeleteIcon />
            </IconButton>
          </Box>
          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel id={`question-type-label-${q.id}`} sx={{ color: '#C0C0C0' }}>
              Question Type
            </InputLabel>
            <Select
              labelId={`question-type-label-${q.id}`}
              value={q.type}
              label="Question Type"
              onChange={(e) => handleQuestionChange(q.id, 'type', e.target.value)}
              sx={{
                color: '#C0C0C0',
                backgroundColor: '#333',
                '.MuiOutlinedInput-notchedOutline': { borderColor: '#39FF14' },
              }}
            >
              {questionTypes.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {q.type === 'checkbox' && (
            <Box sx={{ mb: 2 }}>
              <Typography variant="subtitle1">Options:</Typography>
              {q.options &&
                q.options.map((opt) => (
                  <Box
                    key={opt.id}
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      mt: 1,
                      border: '1px solid #555',
                      p: 1,
                      borderRadius: 1,
                    }}
                  >
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <TextField
                        label="Option Label"
                        variant="outlined"
                        value={opt.label}
                        onChange={(e) => handleOptionChange(q.id, opt.id, e.target.value)}
                        sx={{ mr: 1, input: { color: '#C0C0C0' } }}
                      />
                      <IconButton onClick={() => handleDeleteOption(q.id, opt.id)} color="error">
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                    <OptionMapping
                      questionId={q.id}
                      option={opt}
                      services={services}
                      onMappingChange={(newMapping) => updateOptionMapping(q.id, opt.id, newMapping)}
                    />
                  </Box>
                ))}
              <Button variant="contained" onClick={() => handleAddOption(q.id)} sx={{ mt: 1, backgroundColor: '#39FF14', color: '#1C1C1C' }}>
                Add Option
              </Button>
            </Box>
          )}
        </Box>
      ))}
      <Button variant="contained" onClick={handleAddQuestion} sx={{ mr: 2, backgroundColor: '#39FF14', color: '#1C1C1C' }}>
        Add New Question
      </Button>
      <Button variant="contained" onClick={handleSaveConfiguration} sx={{ backgroundColor: '#39FF14', color: '#1C1C1C' }}>
        Save Configuration
      </Button>
    </Box>
  );
}

export default QuestionnaireBuilder;
