import React, { useState, useEffect } from 'react';
import { 
  Table, TableBody, TableCell, TableHead, TableRow, TextField, Typography, Box 
} from '@mui/material';
import axiosInstance from '../../utils/axios';

function WorkingTimeTable({ serviceId, initialWorkingTimes = [], onUpdate }) {
  const [panels, setPanels] = useState([]);
  // Maintain local working times as strings keyed by panel ID.
  const [workingTimesMap, setWorkingTimesMap] = useState({});

  // Fetch panels for the service.
  useEffect(() => {
    const fetchPanels = async () => {
      try {
        const response = await axiosInstance.get(`/panels/service/${serviceId}`);
        setPanels(response.data);
      } catch (error) {
        console.error('Error fetching panels:', error);
      }
    };
    if (serviceId) {
      fetchPanels();
    }
  }, [serviceId]);

  // Initialize workingTimesMap only when serviceId or initialWorkingTimes change.
  useEffect(() => {
    const map = {};
    initialWorkingTimes.forEach(entry => {
      map[entry.panel] = entry.timeInMinutes.toString();
    });
    setWorkingTimesMap(map);
  }, [serviceId, initialWorkingTimes]);

  // Update workingTimesMap on change
  const handleTimeChange = (panelId, value) => {
    setWorkingTimesMap(prev => ({ ...prev, [panelId]: value }));
  };

  // When a field loses focus, update the parent's state
  const handleBlur = () => {
    const updated = panels.map(panel => ({
      panel: panel._id,
      timeInMinutes: Number(workingTimesMap[panel._id] || 0)
    }));
    onUpdate(updated);
  };

  return (
    <Box sx={{ mt: 2 }}>
      <Typography variant="h6">Working Time Table</Typography>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Panel</TableCell>
            <TableCell>Working Time (min)</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {panels.map(panel => (
            <TableRow key={panel._id}>
              <TableCell>{panel.name}</TableCell>
              <TableCell>
                <TextField
                  type="text"
                  value={workingTimesMap[panel._id] !== undefined ? workingTimesMap[panel._id] : ''}
                  onChange={(e) => handleTimeChange(panel._id, e.target.value)}
                  onBlur={handleBlur}
                  size="small"
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  );
}

export default WorkingTimeTable;
