import React, { useState, useEffect } from "react";
import axiosInstance from "../../utils/axiosAppointment";
import "./CreatePackage.css"; // Reuse CreatePackage styles

const EditPackage = ({ packageData, onPackageUpdated, onClose }) => {
  const [packageName, setPackageName] = useState("");
  const [price, setPrice] = useState(0);
  const [description, setDescription] = useState("");
  const [selectedPanels, setSelectedPanels] = useState([]);
  const [loading, setLoading] = useState(false);
  const [ownerId, setOwnerId] = useState(null);
  const [vehicleClassifications, setVehicleClassifications] = useState([]);
  const [selectedVehicleClass, setSelectedVehicleClass] = useState("");

  useEffect(() => {
    if (!packageData) return;

    console.log("Loaded package data for editing:", packageData);

    setPackageName(packageData.name || "");
    setPrice(packageData.price || 0);
    setDescription(packageData.description || "");
    setSelectedVehicleClass(packageData.vehicleClassificationId || "");
    
    // Ensure panels exist before mapping
    const formattedPanels = packageData.panels
      ? packageData.panels.map((panel) => panel._id || panel)
      : [];

    setSelectedPanels(formattedPanels);
    setOwnerId(packageData.ownerId || null);

    // Fetch available vehicle classifications
  axiosInstance.get("/vehicle-classifications")
  .then((response) => {
    setVehicleClassifications(response.data);
  })
  .catch((error) => {
    console.error("Error fetching vehicle classifications:", error);
  });

  }, [packageData]);

  const handlePanelSelection = (panelId) => {
    setSelectedPanels((prev) =>
      prev.includes(panelId)
        ? prev.filter((id) => id !== panelId)
        : [...prev, panelId]
    );
  };

  const handleSubmit = async () => {
    if (!packageData?._id || !ownerId) {
      console.error("Error: Missing required fields");
      return;
    }

    setLoading(true);
    try {
      const payload = {
        name: packageName,
        price,
        description,
        serviceId: packageData.serviceId,
        brandId: packageData.brandId,
        subBrandId: packageData.subBrandId,
        subBrandName: packageData.subBrandName,
        ownerId,
        panels: selectedPanels.length ? selectedPanels : [],
        vehicleClassificationId: selectedVehicleClass,
      };

      console.log("Updating package payload:", payload);
      await axiosInstance.put(`/packages/${packageData._id}`, payload);

      onPackageUpdated(); // Refresh the list of packages
      onClose(); // Close the edit form
    } catch (error) {
      console.error("Error updating package:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="edit-package-container">
      <h3>Edit Package</h3>
      <input
        type="text"
        placeholder="Package Name"
        value={packageName}
        onChange={(e) => setPackageName(e.target.value)}
      />
      <input
        type="number"
        placeholder="Price"
        value={price}
        onChange={(e) => setPrice(Number(e.target.value))}
      />
      <textarea
        placeholder="Description"
        value={description}
        onChange={(e) => setDescription(e.target.value)}
      />

      <h4>Select Panels</h4>
      <div className="panel-selection">
        {packageData?.panels && packageData.panels.length > 0 ? (
          packageData.panels.map((panel) => (
            <label key={panel._id || panel}>
              <input
                type="checkbox"
                value={panel._id || panel}
                checked={selectedPanels.includes(panel._id || panel)}
                onChange={() => handlePanelSelection(panel._id || panel)}
              />
              {panel.name || "Unknown Panel"}
            </label>
          ))
        ) : (
          <p>No panels available.</p>
        )}
      </div>

      <h4>Vehicle Classification</h4>
        <select
        value={selectedVehicleClass}
        onChange={(e) => setSelectedVehicleClass(e.target.value)}
        >
        <option value="">Select Classification</option>
        {vehicleClassifications.map((classification) => (
            <option key={classification._id} value={classification._id}>
            {classification.name}
            </option>
        ))}
        </select>

      <div className="edit-package-buttons">
        <button onClick={handleSubmit} disabled={loading}>
          {loading ? "Updating..." : "Save Changes"}
        </button>
        <button onClick={onClose} className="cancel-btn">
          Cancel
        </button>
      </div>
    </div>
  );
};

export default EditPackage;
