//axios.js
import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

axiosInstance.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`;
  }
  // Add ownerId header if available
  const ownerId = localStorage.getItem('ownerId');
  if (ownerId) {
    config.headers['X-Owner-Id'] = ownerId;
  }
  return config;
});

export default axiosInstance;
