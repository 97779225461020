import React, { useState } from "react";
import axiosInstance from "../../utils/axiosAppointment";

const CreateVehicleClassification = () => {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const handleCreate = async () => {
    if (!name.trim()) {
      setError("Classification name is required.");
      return;
    }

    try {
      const response = await axiosInstance.post("/vehicle-classifications", {
        name,
        description,
      });

      if (response.status === 201) {
        setSuccess("Vehicle classification created successfully!");
        setName("");
        setDescription("");
      }
    } catch (err) {
      setError("Error creating classification.");
    }
  };

  return (
    <div className="vehicle-classification">
      <h3>Create Vehicle Classification</h3>
      {error && <p className="error-message">{error}</p>}
      {success && <p className="success-message">{success}</p>}
      
      <input
        type="text"
        placeholder="Classification Name"
        value={name}
        onChange={(e) => setName(e.target.value)}
      />
      <input
        type="text"
        placeholder="Description (Optional)"
        value={description}
        onChange={(e) => setDescription(e.target.value)}
      />
      
      <button onClick={handleCreate}>Save Classification</button>
    </div>
  );
};

export default CreateVehicleClassification;
